export function buildCoveragePeriod (resource: any, key: string): string {
  return resource ? resource.period[key] : ''
}

export function buildCoverageStatus (resource: any): string {
  return resource ? resource.status : ''
}
  
export function buildCoverageIdentifier (resource: any) :string {
  return resource ? resource.identifier[0].value : ''
}
  
export function buildCoverageSubId (resource: any): string {
  return resource ? resource.subscriberId : ''
}
  
export function buildCoveragePayer (resource: any): string {
  return resource ? resource.payor[0].reference : ''
}
  
export function buildCoverageBeneficiary (resource: any): string {
  return resource ? resource.beneficiary.reference : ''
}
  
export function buildCoverageRelationship (resource: any): string {
  let relationType = resource && resource.relationship ? resource.relationship.text : ''
  relationType = relationType == '' && resource?.link[0]?.relation ? resource.link[0].relation : ''
  return relationType
}
  