import React, { FC, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useFormik } from 'formik'
import { RequestorSchema } from '../../../utils/validation'

import './index.css'

interface Props {
    initialValues: any;
    onSubmit: (values: any) => void;
}

const Requestor: FC<Props> = ({ initialValues, onSubmit }: Props) => {

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: RequestorSchema,
    onSubmit: (values) => {
      onSubmit(values)
    },
    enableReinitialize: true
  })

  return (
    <Form noValidate onSubmit={formik.handleSubmit} id='requestor'>
      <Row>
        <Form.Group as={Col} md={6} sm={12}>
          <Form.Label>Your First Name:</Form.Label>
          <Form.Control 
            type='text'
            name='firstName'
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.errors.firstName && !!formik.touched.firstName}
          />
          <Form.Control.Feedback type='invalid'>
            {formik.errors.firstName}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={6} sm={12}>
          <Form.Label>Your Last Name:</Form.Label>
          <Form.Control 
            type='text'
            name='lastName'
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.errors.lastName && !!formik.touched.lastName}
          />
          <Form.Control.Feedback type='invalid'>
            {formik.errors.lastName}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <div>
        <Form.Check
          type='radio'
          label={<label>I am registering for myself and I am 18 years old or older.</label>}
          checked={formik.values.type === 'member'}
          onChange={(event) => {
            setTimeout(() => formik.setFieldValue('type', 'member'))
            setTimeout(() => formik.setFieldTouched('type', true))
          }}
          isInvalid={!!formik.errors.type && !!formik.touched.type}
        />
        <Form.Check
          type='radio'
          label={<label>I am a parent/guardian and I am registering for my minor child.</label>}
          checked={formik.values.type === 'parent'}
          onChange={(event) => {
            setTimeout(() => formik.setFieldValue('type', 'parent'))
            setTimeout(() => formik.setFieldTouched('type', true))
          }}
          isInvalid={!!formik.errors.type && !!formik.touched.type}
        />
        {
          formik.errors.type && formik.touched.type &&  (
            <Form.Text className='form-error mb-3'>
              {formik.errors.type}
            </Form.Text>
          )
        }
      </div>
      <Button className='memberSubmitBtn' type='submit'>
          Next &gt;&gt;
      </Button>
    </Form>
  )
}

export default Requestor
