import React, { FC } from 'react'
import { Button, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatDateInTimeZone } from '../../../../utils/helpers'
import { buildPatientId } from '../../../../utils/fhirUtils/patient'
import { formatEobDataForTable } from '../../../../utils/fhirUtils/eob'

interface Props {
    eobData: any;
    patientData: any;
    isFetching: boolean;
    handleNext: (url: string) => void;
}

const EobList: FC<Props> = ({ patientData, eobData, handleNext, isFetching }: Props) => {
  const patientEobs = formatEobDataForTable(eobData, buildPatientId(patientData))
  
  const nextLink = eobData && eobData.link.find((l: any) => l.relation === 'next')
  const previousLink = eobData && eobData.link.find((l: any) => l.relation === 'previous')

  return (
    <Table size='sm' hover>
      <thead>
        <tr>
          <th scope="col">Member ID</th>
          <th scope="col">Claim ID</th>
          <th scope="col">Type</th>
          <th scope="col">Billable Start</th>
          <th scope="col">Billable End</th>
          <th scope="col">View Detail</th>
        </tr>
      </thead>
      <tbody>
        {
          isFetching ? (
            <tr>
              <td colSpan={6} rowSpan={15} className='d-flex'>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
                    Loading Member EOBs...
              </td>
            </tr>
          ) : patientEobs.length === 0 ? (
            <tr>
              <td colSpan={6} rowSpan={15} className='text-center'>
                No Explanation Of Benefits found for this member.
              </td>
            </tr>
          ) : (
            <>
              {
                patientEobs.map((eob: any) => (
                  <tr key={eob.eobId}>
                    <th scope="row">{eob.memberId}</th>
                    <td scope='col'>{eob.claimId}</td>
                    <td scope='col'>{eob.type}</td>
                    <td scope='col'>{eob.billableStart ? formatDateInTimeZone(eob.billableStart, 'MM/dd/yyyy', 'UTC') : ''}</td>
                    <td scope='col'>{eob.billableEnd ? formatDateInTimeZone(eob.billableEnd, 'MM/dd/yyyy', 'UTC') : ''}</td>
                    <td scope='col'><Link to={`/patient/${buildPatientId(patientData)}/EOBs/${eob.eobId}`}>View Detail</Link></td>
                  </tr>
                ))
              }
            </>
          )
        }
      </tbody>
      <tfoot>
        { patientEobs.length > 0 &&
          <tr>
            <td scope="row" colSpan={8}>
              <Button
                variant='light'
                disabled={!previousLink}
                onClick={() => handleNext(previousLink.url)}
              >
                {'<'} Back
              </Button>
              <Button
                className='custom'
                disabled={!nextLink}
                onClick={() => handleNext(nextLink.url)}
              >
                Next {'>'}
              </Button>
            </td>
          </tr> 
        }
      </tfoot>
    </Table>
  )
}

export default EobList