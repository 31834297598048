import React, { FC, useState } from 'react'
import { Breadcrumb, Button, Container, Form, Spinner, Table, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useProviders from '../../../hooks/admin/useProviders'
import isAuthenticated from '../../../routes/PrivateRoute'
import { formatDateInTimeZone } from '../../../utils/helpers'

import './index.css'

const Providers: FC = () => {
  const [searchName, setSearchName] = useState('')
  const [searchId, setSearchId] = useState('')
  const [currentPage, setCurrentPage] = useState(0)

  const { isLoading, data, isPreviousData, refetch, isSuccess } = useProviders(searchName, searchId)

  const handleProviderSearch = (e: any) => {
    e.preventDefault()
    refetch()
  }

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
      }}
    > 
      <Breadcrumb>
        <Breadcrumb.Item href='/admin'>Admin Home</Breadcrumb.Item>
        <Breadcrumb.Item active aria-current="page">Provider Directory</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Provider Directory</h2>
      <Form noValidate onSubmit={handleProviderSearch} className='form-inline'>
        <Form.Group>
          <Form.Label style={{ margin: '0 1em 0 0' }}>Find a Provider</Form.Label>
          <Form.Control
            style={{ margin: '0 1em 0 0' }}
            type='text'
            name='searchName'
            placeholder="Type Provider Name"
            value={searchName}
            onChange={(e: any) => setSearchName(e.target.value)}
          />
          <Form.Control
            style={{ margin: '0 1em 0 0' }}
            type='text'
            name='searchId'
            placeholder="Type Provider ID"
            value={searchId}
            onChange={(e: any) => setSearchId(e.target.value)}
          />
        </Form.Group>
        <Button className='searchBtn' type='submit'>
          Search
        </Button>
      </Form>
      <Table hover size='sm' className='mt-3'>
        <thead>
          <tr>
            <th scope="col" >ID (NPI)</th>
            <th scope="col" >Provider Name</th>
            <th scope="col" >Resource Type</th>
            <th scope="col" >Last Updated</th>
            <th scope="col" >Fhir Data</th>
          </tr>
        </thead>
        <tbody>
          {
            isLoading && (
              <tr>
			          <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                  <Spinner 
                    as='span'
                    animation='border'
                    role='status'
                    aria-hidden='true'
                  />
                </td>
			        </tr>
            )
          }
          {
            isSuccess && data?.length > 0 ? ( 
              <> 
                {
                  data.map((provider: any) => (
                    <tr key={provider.identifier[0].value}> 
                      <td>{provider.id}</td> 
                      <td>{`${provider.name[0].given[0] || ''} ${provider.name[0].family || ''}`}</td>
                      <td>{provider.resourceType}</td>
                      <td>{formatDateInTimeZone(provider.meta.lastUpdated, 'MM/dd/yyyy', 'UTC')}</td>
                      <td><Link to={`/provider/${provider.id}?name=${searchName}&id=${searchId}`}>View Data</Link></td>
                    </tr>
                  ))
                }
              </>
            ) : (
              <>
			          { data?.length === 0 &&
                  <tr>
                    <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                      <span>Provider Not found!</span>
                    </td>
                  </tr>
                }
              </>
            )
          }
			  </tbody>
      </Table>
    </Container>
  )
}

export default isAuthenticated(Providers)