import React, { FC } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { buildPatientId, buildPatientName, buildPatientGender, buildPatientBirthday, buildPatientAddress, buildPatientTelecom, patientHasEmail, buildPatientGeneralPractitioner } from '../../../utils/fhirUtils/patient'
import { capitalizeFirstLetter } from '../../../utils/helpers'

interface Props {
  patientData: any;
  loading: boolean;
}

const MemberProfile: FC<Props> = ({ patientData, loading }: Props) => {
  const patientAddress = patientData ? patientData.address : []
  const patientTelecom = patientData ? patientData.telecom : []
  const patientAddressData = buildPatientAddress(patientAddress)
  const patientPhoneData = buildPatientTelecom(patientTelecom, 'phone')
  const patientEmailData = buildPatientTelecom(patientTelecom, 'email')
  return (
    <>
      <h3>Member Profile: {patientData ? buildPatientName(patientData.name) : ''} {buildPatientId(patientData)}</h3>
      <section>
        {
          loading ? (
            <div className='d-flex dataContainer'>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              Loading Member Profile...
            </div>
          ) : patientData && Object.keys(patientData).length > 0 ? (
            <dl className='dataContainer'>
              <Row>
                <Col sm={3}>
                  <dt>ID</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildPatientId(patientData)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Name</dt>
                </Col>
                <Col sm={9}>
                  <dd>{patientData ? buildPatientName(patientData.name) : ''}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Gender</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildPatientGender(patientData)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Birth Date</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildPatientBirthday(patientData)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Address</dt>
                </Col>
                <Col sm={9}>
                  {patientAddressData.map((patientAddress) => (
                    <dd key={patientAddress.address}>
                      {patientAddress.use && `${capitalizeFirstLetter(patientAddress.use)}:`} {patientAddress.address}
                    </dd>
                  ))}
                </Col>
                <Col sm={3}>
                  <dt>Phone</dt>
                </Col>
                <Col sm={9}>
                  {patientPhoneData.map((patientTelecom, index) => (
                    <dd key={index}>
                      {patientTelecom}
                    </dd>
                  ))}
                </Col>
                {
                  patientHasEmail(patientTelecom) && (
                    <>
                      <Col sm={3}>
                        <dt>Email</dt>
                      </Col>
                      <Col sm={9}>
                        {patientEmailData.map((patientTelecom, index) => (
                          <dd key={index}>
                            {patientTelecom}
                          </dd>
                        ))}
                      </Col>
                    </>
                  )
                }
                <Col sm={3}>
                  <dt>General Practitioner</dt>
                </Col>
                <Col sm={9}>
                  <dd>{
                    buildPatientGeneralPractitioner(patientData.generalPractitioner)
                  }</dd>
                </Col>
                <Col sm={3}>
                  <dt>Resource URL</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    https://18.233.54.217:9090/fhir/Patient/{buildPatientId(patientData)}
                  </dd>
                </Col>
              </Row>
            </dl>
          ) : 
            <tr>
              <td colSpan={6} rowSpan={15} className='text-center'>
                  No Profile found for this member.
              </td>
            </tr>
        }
      </section>
    </>
  )
}

export default MemberProfile