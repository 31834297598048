import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row, Spinner, Breadcrumb } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import MaskedInput from 'react-maskedinput'
import { useFormik } from 'formik'
import { AppRegisterSchema } from '../../../utils/validation'
import { prepareValueForDb } from '../../../utils/helpers'
import useGetDeveloperApplication from '../../../hooks/developer/useGetDeveloperApplication'
import useUpdateDeveloperApplication from '../../../hooks/developer/useUpdateDeveloperApplication'

interface AppEditRouteParams {
  appId: string;
}

const AppEdit: FC = () => {
  const [showAlertState, setShowAlertState] = useState({ show: false, variant: 'info', message: '' })
  const { appId } = useParams<AppEditRouteParams>()
  const { data: app, isError, refetch } = useGetDeveloperApplication(appId)
  const developerUpdateApp = useUpdateDeveloperApplication()

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (developerUpdateApp.isSuccess) {
      setShowAlertState({
        show: true,
        variant: 'success',
        message: 'Successfully updated application.'
      })
    }

    if (developerUpdateApp.isError) {
      setShowAlertState({
        show: true,
        variant: 'danger',
        message: `Unable to update application: ${developerUpdateApp.error?.response.text}`
      })
    }
  }, [developerUpdateApp.isSuccess, developerUpdateApp.isError])

  const appForm = useFormik({
    initialValues: {
      ...app,
      address2: app && app.address2 ? app.address2 : '',
      otherDirectory: app && app.otherDirectory ? app.otherDirectory : '',
      otherDir: app && app.otherDirectory ? true : false,
      directoryChecked: ''
    },
    onSubmit: (values) => {
      const updatedApp = {
        appId: values.appId,
        readCarin: values.readCarin,
        companyName: values.companyName,
        address_1: values.address1,
        address_2: prepareValueForDb(values.address2),
        city: values.city,
        state: values.state,
        zip: values.zip,
        url: values.url,
        appName: values.appName,
        webUrl: values.webUrl,
        termsUrl: values.termsUrl,
        redirectUrl: values.redirectUrl,
        iosAppId: values.iosAppId,
        iosAppUrl: values.iosAppUrl,
        androidAppId: values.androidAppId,
        androidAppUrl: values.androidAppUrl,
        caqhDirectory: values.caqhDirectory,
        changeHealthcareDirectory: values.changeHealthcareDirectory,
        medEffectsDirectory: values.medEffectsDirectory,
        otherDirectory: prepareValueForDb(values.otherDirectory),
        contactName: values.contactName,
        email: values.email,
        phone: values.phone
      }
      developerUpdateApp.mutate(updatedApp)
    },
    enableReinitialize: true,
    validationSchema: AppRegisterSchema
  })

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        overflow: 'auto',
        maxWidth: 840
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item href="/developers">CCHP API Information</Breadcrumb.Item>
        <Breadcrumb.Item active>Developer Registration</Breadcrumb.Item>
      </Breadcrumb>
      {
        isError ? (
          <Alert variant='danger'>
            Unable to get your application. <Alert.Link href='/developers' className='ml-2'>Go Back</Alert.Link>
          </Alert>
        ) : (
          <Card 
            className='mt-2' 
            style={{ 
              border: '2px solid #ADADAD',
              borderRadius: 8
            }}
          >
            <Card.Body>
              <Form noValidate onSubmit={appForm.handleSubmit} className='mt-3'>
                {
                  showAlertState.show && (
                    <Alert variant={showAlertState.variant} dismissible onClose={() => setShowAlertState((ps) => ({ ...ps, show: false }))}>
                      {showAlertState.message}
                    </Alert>
                  )
                }
                <h3>Attestations</h3>
                <div>
                  <Form.Check
                    name='readCarin'
                    type='checkbox'
                    label={
                      <div>
                    I have reviewed and agreed to the <a href='https://www.carinalliance.com/our-work/trust-framework-and-code-of-conduct/' target='_blank' rel="noreferrer">Carin Code of Conduct</a>
                      </div>
                    }
                    checked={appForm.values.readCarin}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.readCarin && !!appForm.touched.readCarin}
                  />
                  {
                    !!appForm.errors.readCarin && !!appForm.touched.readCarin && (
                      <Form.Text className='form-error'>
                        {appForm.errors.readCarin}
                      </Form.Text>
                    )
                  }
                </div>
                <h3 className='mt-2'>Company Information</h3>
                <Form.Group>
                  <Form.Label>Company Name:</Form.Label>
                  <Form.Control
                    name='companyName'
                    type='text'
                    value={appForm.values.companyName}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.companyName && !!appForm.touched.companyName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.companyName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Address:</Form.Label>
                  <Form.Control
                    name='address1'
                    type='text'
                    value={appForm.values.address1}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.address1 && !!appForm.touched.address1}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.address1}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Address 2:</Form.Label>
                  <Form.Control
                    name='address2'
                    type='text'
                    value={appForm.values.address2}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                  />
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>City:</Form.Label>
                  <Form.Control
                    name='city'
                    type='text'
                    value={appForm.values.city}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.city && !!appForm.touched.city}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>State/Province:</Form.Label>
                  <Form.Control
                    name='state'
                    as='select'
                    value={appForm.values.state}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.state && !!appForm.touched.state}
                  >
                    <option value="" disabled>Choose State/Province</option>
                    <option value="Alabama">AL</option>
                    <option value="Alaska">AK</option>
                    <option value="American Samoa">AS</option>
                    <option value="Arizona">AZ</option>
                    <option value="Arkansas">AR</option>
                    <option value="California">CA</option>
                    <option value="Colorado">CO</option>
                    <option value="Connecticut">CT</option>
                    <option value="Delaware">DE</option>
                    <option value="District Of Columbia">DC</option>
                    <option value="Federated States Of Micronesia">FM</option>
                    <option value="Florida">FL</option>
                    <option value="Georgia">GA</option>
                    <option value="Guam">GU</option>
                    <option value="Hawaii">HI</option>
                    <option value="Idaho">ID</option>
                    <option value="Illinois">IL</option>
                    <option value="Indiana">IN</option>
                    <option value="Iowa">IA</option>
                    <option value="Kansas">KS</option>
                    <option value="Kentucky">KY</option>
                    <option value="Louisiana">LA</option>
                    <option value="Maine">ME</option>
                    <option value="Marshall Islands">MH</option>
                    <option value="Maryland">MD</option>
                    <option value="Massachusetts">MA</option>
                    <option value="Michigan">MI</option>
                    <option value="Minnesota">MN</option>
                    <option value="Mississippi">MS</option>
                    <option value="Missouri">MO</option>
                    <option value="Montana">MT</option>
                    <option value="Nebraska">NE</option>
                    <option value="Nevada">NV</option>
                    <option value="New Hampshire">NH</option>
                    <option value="New Jersey">NJ</option>
                    <option value="New Mexico">NM</option>
                    <option value="New York">NY</option>
                    <option value="North Carolina">NC</option>
                    <option value="North Dakota">ND</option>
                    <option value="Northern Mariana Islands">MP</option>
                    <option value="Ohio">OH</option>
                    <option value="Oklahoma">OK</option>
                    <option value="Oregon">OR</option>
                    <option value="Palau">PW</option>
                    <option value="Pennsylvania">PA</option>
                    <option value="Puerto Rico">PR</option>
                    <option value="Rhode Island">RI</option>
                    <option value="South Carolina">SC</option>
                    <option value="South Dakota">SD</option>
                    <option value="Tennessee">TN</option>
                    <option value="Texas">TX</option>
                    <option value="Utah">UT</option>
                    <option value="Vermont">VT</option>
                    <option value="Virgin Islands">VI</option>
                    <option value="Virginia">VA</option>
                    <option value="Washington">WA</option>
                    <option value="West Virginia">WV</option>
                    <option value="Wisconsin">WI</option>
                    <option value="Wyoming">WY</option>
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.state}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Zip/Postal Code:</Form.Label>
                  <Form.Control
                    name='zip'
                    type='text'
                    value={appForm.values.zip}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.zip && !!appForm.touched.zip}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.zip}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Company URL:</Form.Label>
                  <Form.Control
                    name='url'
                    type='text'
                    value={appForm.values.url}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.url && !!appForm.touched.url}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.url}
                  </Form.Control.Feedback>
                </Form.Group>
                <h3 className='mt-2'>App Information</h3>
                <Form.Group>
                  <Form.Label>App Name:</Form.Label>
                  <Form.Control
                    name='appName'
                    type='text'
                    value={appForm.values.appName}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.appName && !!appForm.touched.appName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.appName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>App Description:</Form.Label>
                  <Form.Control
                    name='appDescription'
                    as='textarea'
                    rows={3}
                    value={appForm.values.appDescription}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.appDescription && !!appForm.touched.appDescription}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.appDescription}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Terms and Conditions URL:</Form.Label>
                  <Form.Control
                    name='termsUrl'
                    type='text'
                    value={appForm.values.termsUrl}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.termsUrl && !!appForm.touched.termsUrl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.termsUrl}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Redirect URL:</Form.Label>
                  <Form.Control
                    name='redirectUrl'
                    type='text'
                    value={appForm.values.redirectUrl}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.redirectUrl && !!appForm.touched.redirectUrl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.redirectUrl}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>iOS App ID:</Form.Label>
                  <Form.Control
                    name='iosAppId'
                    type='text'
                    value={appForm.values.iosAppId}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.iosAppId && !!appForm.touched.iosAppId}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.iosAppId}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>iOS App URL:</Form.Label>
                  <Form.Control
                    name='iosAppUrl'
                    type='text'
                    value={appForm.values.iosAppUrl}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.iosAppUrl && !!appForm.touched.iosAppUrl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.iosAppUrl}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Android App ID:</Form.Label>
                  <Form.Control
                    name='androidAppId'
                    type='text'
                    value={appForm.values.androidAppId}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.androidAppId && !!appForm.touched.androidAppId}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.androidAppId}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Android App URL:</Form.Label>
                  <Form.Control
                    name='androidAppUrl'
                    type='text'
                    value={appForm.values.androidAppUrl}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.androidAppUrl && !!appForm.touched.androidAppUrl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.androidAppUrl}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>
                Which directories have you registered with?
                  </Form.Label>
                  <Row>
                    <Col md={4} xs={12}>
                      <Form.Check
                        name='caqhDirectory'
                        type='checkbox'
                        label='CAQH'
                        checked={appForm.values.caqhDirectory}
                        onChange={appForm.handleChange}
                        onBlur={appForm.handleBlur}
                        isInvalid={!!appForm.errors.directoryChecked && !!appForm.touched.caqhDirectory}
                      />
                    </Col>
                    <Col md={4} xs={12}>
                      <Form.Check
                        name='changeHealthcareDirectory'
                        type='checkbox'
                        label='Change Healthcare'
                        checked={appForm.values.changeHealthcareDirectory}
                        onChange={appForm.handleChange}
                        onBlur={appForm.handleBlur}
                        isInvalid={!!appForm.errors.directoryChecked && !!appForm.touched.changeHealthcareDirectory}
                      />
                    </Col>
                    <Col md={4} xs={12}>
                      <Form.Check
                        name='medEffectsDirectory'
                        type='checkbox'
                        label='Med Effects'
                        checked={appForm.values.medEffectsDirectory}
                        onChange={appForm.handleChange}
                        onBlur={appForm.handleBlur}
                        isInvalid={!!appForm.errors.directoryChecked && !!appForm.touched.medEffectsDirectory}
                      />
                    </Col>
                  </Row>
                  <Row className='mt-sm-3'>
                    <Col xs={2}>
                      <Form.Check
                        name='otherDir'
                        type='checkbox'
                        label='Other'
                        checked={appForm.values.otherDir}
                        onChange={appForm.handleChange}
                        onBlur={appForm.handleBlur}
                        isInvalid={!!appForm.errors.directoryChecked && !!appForm.touched.otherDir}
                      />
                    </Col>
                    <Col xs={6}>
                      <Form.Control
                        type='text'
                        name='otherDirectory'
                        value={appForm.values.otherDirectory}
                        onChange={appForm.handleChange}
                        onBlur={appForm.handleBlur}
                        disabled={!appForm.values.otherDir}
                        isInvalid={!!appForm.errors.otherDirectory && !!appForm.touched.otherDirectory}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {appForm.errors.otherDirectory}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  {
                    !!appForm.errors.directoryChecked && (appForm.touched.caqhDirectory || appForm.touched.changeHealthcareDirectory || appForm.touched.medEffectsDirectory || appForm.touched.otherDir) && (
                      <Form.Text className='form-error'>
                    Please select at least one directory.
                      </Form.Text>
                    )
                  }
                </Form.Group>
                <h3 className='mt-2'>App Contact</h3>
                <Form.Group className='mt-3'>
                  <Form.Label>Your Name:</Form.Label>
                  <Form.Control
                    name='contactName'
                    type='text'
                    value={appForm.values.contactName}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.contactName && !!appForm.touched.contactName}
                  />
                  <Form.Control.Feedback type='invalid'> 
                    {appForm.errors.contactName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Your Phone:</Form.Label>
                  <Form.Control
                    as={MaskedInput}
                    mask='(111)-111-1111'
                    name='phone'
                    value={appForm.values.phone}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.phone && !!appForm.touched.phone}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Your Email:</Form.Label>
                  <Form.Control
                    name='email'
                    type='text'
                    value={appForm.values.email}
                    onChange={appForm.handleChange}
                    onBlur={appForm.handleBlur}
                    isInvalid={!!appForm.errors.email && !!appForm.touched.email}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {appForm.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Col xs={12}>
                    <Button className='float-right memberSubmitBtn' type='submit'>
                      {
                        developerUpdateApp.isLoading ? (
                          <Spinner 
                            as='span'
                            animation='border'
                            role='status'
                            size='sm'
                            aria-hidden='true'
                          />
                        ) : 'Register'
                      }
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        )
      }
    </Container>
  )
}

export default AppEdit