import React, { FC } from 'react'
import EobList from './EobList'
import EobDetail from './EobDetail'
import { buildPatientId, buildPatientName } from '../../../utils/fhirUtils/patient'
import { Spinner } from 'react-bootstrap'

interface Props {
  eobData: any;
  patientData: any;
  eobDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleNext: (url: string) => void;
}

const Eobs: FC<Props> = ({eobData, patientData, eobDetailId, page, isFetching, handleNext }: Props) => {
  return (
    <div>
      <h3>{eobDetailId ? 'EOB Detail' : 'EOBs'}: {patientData ? buildPatientName(patientData.name) : ''} {buildPatientId(patientData)}</h3>
      {
        eobDetailId && page === 'EOBs' 
          ? <EobDetail eobDetailId={eobDetailId} eobData={eobData} patientData={patientData}/> 
          : <EobList eobData={eobData} patientData={patientData} handleNext={handleNext} isFetching={isFetching} />
      }
    </div>
  )
}
export default Eobs