import React, { FC } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import useFhirPractitionerMetadata from '../../hooks/developer/useFhirPractitionerMetadata'

const ProviderApi: FC = () => {

  const { data: metadata } = useFhirPractitionerMetadata()

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: 840
      }}
    >
      <div>
			
        <Breadcrumb>
          <Breadcrumb.Item href="/developers">CCHP API Information</Breadcrumb.Item>
          <Breadcrumb.Item active>Provider Access API Capability Statement</Breadcrumb.Item>
        </Breadcrumb>
		
        <h2>CCHP API Information</h2>
					
        <h3>Provider Directory API Capability Statement</h3>	
					
        <pre style={{ height: 400, overflow: 'auto' }}>
          <code>
            {JSON.stringify(metadata, null, 2)}
          </code>
        </pre>
			
      </div>
    </Container>
        
  )
}
  
export default ProviderApi