
import OrgPractName from '../../types/OrgPractName'

export function buildPractitionerLastUpdated (resource: any): string {
  let practitionerLastUpdated = ''

  const coverageMetaData = resource.meta
  for (let i = 0; i < coverageMetaData.length; i++) {
    practitionerLastUpdated = (coverageMetaData[i].lastUpdated !== undefined) ? coverageMetaData.lastUpdated : ''
  }
  return practitionerLastUpdated
}

export function buildProviderPhone (providerPhone: any): Array<string> {
  const phone = providerPhone.replace(/[^\d]/g, '')
  if (phone.length == 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
  return phone
}

export function buildPractitionerName (names: Array<OrgPractName>): string {
  if (names) {
    const officialName = names.find((names: OrgPractName) => names.use === 'official')
    let familyName
    let givenName
    if (officialName) {
      familyName = officialName?.family || ''
      givenName = officialName?.given.join(' ')
    } else {
      const { family, given } = names[0]
      familyName = family || ''
      givenName = given.join(' ')
    }
    return `${givenName} ${familyName}`
  }
  return ''
}

export function buildProviderId (provider: any): string {
  const providerId = provider && provider.id !=undefined ? provider.id : ''
  return providerId
}

export function buildProviderGender (provider: any): string {
  const providerGender = provider && provider.gender !=undefined ? provider.gender : ''
  return providerGender
}