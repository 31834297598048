import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'
import { getToken } from '../../utils/helpers'

export default function useProviders (searchTerm = '', searchId = ''): any {
  const token = getToken()
  return useQuery([ 'Provider', searchTerm, searchId], async () => {
    return await request.get(`${getApiServer()}/providers`).query({ searchTerm, searchId }).set('Authorization', 'Bearer ' + token ).set('Accept', 'application/json').then((res) => res.body)
  }, {
    initialData: null,
    staleTime: Infinity,
    cacheTime: 3600000,
    retry: 0,
    keepPreviousData: true,
    enabled: false
  })
}