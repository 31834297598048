import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'
import { getToken } from '../../utils/helpers'

export default function useAccounts (accountOwnerType = '', accountStatus = '', page = 0): any {
  const token = getToken()
  return useQuery(['accounts', accountOwnerType, accountStatus, page], async () => {
    return await request.get(`${getApiServer()}/accounts`).query({ accountOwnerType, accountStatus, page }).set('Authorization', 'Bearer ' + token ).set('Accept', 'application/json').then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 0,
    keepPreviousData: true,
    enabled: true
  })
}