import React, { FC } from 'react'
import { Form, Alert, Button } from 'react-bootstrap'

interface Props {
    formHandler: any;
    alertState: { show: boolean; variant: string; message: string; };
    closeAlert: () => void;
}

const ChangePasswordForm: FC<Props> = ({ formHandler, alertState, closeAlert }: Props) => {
  return (
    <>
      <h3 className='headerText'>Change Password</h3>
      <Form noValidate onSubmit={formHandler.handleSubmit} id='login'>
        {
          alertState.show && (
            <Alert variant={alertState.variant} dismissible onClose={closeAlert}>
              {alertState.message}
            </Alert>
          )
        }
        <Form.Group className='custom'>
          <Form.Label>Password:</Form.Label>
          <Form.Control 
            type='password' 
            name='password' 
            value={formHandler.values.password}
            onChange={formHandler.handleChange}
            onBlur={formHandler.handleBlur}
            isInvalid={!!formHandler.errors.password && formHandler.touched.password}
            placeholder='Create Password'
            autoComplete='new-password'
          />
          <Form.Control.Feedback type='invalid'>
            {formHandler.errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='custom mt-2'>
          <Form.Label>Enter Password Again:</Form.Label>
          <Form.Control 
            type='password' 
            name='passwordAgain' 
            value={formHandler.values.passwordAgain}
            onChange={formHandler.handleChange}
            onBlur={formHandler.handleBlur}
            isInvalid={!!formHandler.errors.passwordAgain && formHandler.touched.passwordAgain}
            placeholder='Repeat Password'
            autoComplete='new-password'
          />
          <Form.Control.Feedback type='invalid'>
            {formHandler.errors.passwordAgain}
          </Form.Control.Feedback>
        </Form.Group>
        <div>
          <Button variant='link' href='/login' className='mt-3 mr-3'>Go Back</Button>
          <Button type='submit' className='custom mt-3'>
            Submit
          </Button>
        </div>
      </Form>
    </>
  )
}

export default ChangePasswordForm