import React, { FC, useEffect, useState } from 'react'
import { Alert, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Member from '../../components/MemberRegistration/Member'
import Requestor from '../../components/MemberRegistration/Requestor'
import ContactInfo from '../../components/MemberRegistration/ContactInfo'
import ValidateEmail from '../../components/MemberRegistration/ValidateEmail'
import CreateAccount from '../../components/MemberRegistration/CreateAccount'
import useValidateMember from '../../hooks/authentication/useValidateMember'
import useSendCode from '../../hooks/authentication/useSendCode'
import useCreateMember from '../../hooks/authentication/useCreateMember'
import useValidateEmail from '../../hooks/authentication/useValidateEmail'
import './index.css'

interface MatchParams {
  step: string;
}

const steps = ['requestor', 'member', 'contact', 'validate', 'create']

const initialMemberState = {
  memberId: '',
  dob: null,
  ssn: ''
}

const initialRequestorState = {
  type: '',
  firstName: '',
  lastName: '',
}

const initialContactInfoState = {
  email: ''
}

const initalCreateAccountState = {
  username: '',
  password: '',
  passwordAgain: ''
}

const Register: FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [memberState, setMemberState] = useState(initialMemberState)
  const [requestorState, setRequestorState] = useState(initialRequestorState)
  const [contactInfoState, setContactInfoState] = useState(initialContactInfoState)
  const [createAccountState, setCreateAccountState] = useState(initalCreateAccountState)

  const match = useRouteMatch<MatchParams>()
  const history = useHistory()
  const { step } = match.params

  const goToNextStep = () => {
    setActiveStep(activeStep + 1)
    history.push(`/register/${steps[activeStep + 1]}`)
  }

  const validatedMember = useValidateMember()
  useEffect(() => {
    if (validatedMember.isSuccess && activeStep === 1) {
      if (validatedMember.data) {
        if (validatedMember.data.length === 1 && validatedMember.data[0].status !== 'ACTIVE') {
          goToNextStep()
        }
      }
    }
  }, [validatedMember.isSuccess, activeStep])

  const sendMemberCode = useSendCode()
  useEffect(() => {
    if (sendMemberCode.isSuccess && activeStep === 2) {
      if (sendMemberCode.data === 'ok') {
        goToNextStep()
      }
    }
  }, [sendMemberCode.isSuccess, activeStep])

  const handleGoBackToContactStep = () => {
    history.push('/register/contact')
    sendMemberCode.reset()
  }

  const validateEmail = useValidateEmail()
  useEffect(() => {
    if (validateEmail.isSuccess && activeStep === 3) {
      goToNextStep()
    }
  }, [validateEmail.isSuccess, activeStep])

  const handleSendNewCode = () => {
    sendMemberCode.mutate({
      ...contactInfoState,
      ...validatedMember.data[0],
      type: requestorState.type
    })
  }

  const createMember = useCreateMember()
  
  useEffect(() => {
    if (!step) {
      history.push('/register/requestor')
    } else {
      setActiveStep(steps.indexOf(step))
    }
  },[step])

  const handleSubmit = (values: any) => {
    if (activeStep < 4) {
      switch (activeStep) {
      case 0:
        setRequestorState(values)
        goToNextStep()
        break
      case 1:
        validatedMember.mutate(values)
        setMemberState(values)
        break
      case 2:
        setContactInfoState(values)
        sendMemberCode.mutate({
          email: values.email,
          firstName: requestorState.firstName,
          lastName: requestorState.lastName,
          memberId: memberState.memberId,
          type: requestorState.type
        })
        break
      case 3: 
        validateEmail.mutate(values)
        break
      default:
        break
      }
    } else {
      setCreateAccountState(values)
      createMember.mutate({
        ...values,
        member: validateEmail.data?.member
      })
    }
  }
  const renderRegistrationStep = (step: number) => {
    switch (step) {
    case 0:
      return (
        <Requestor
          initialValues={requestorState}
          onSubmit={handleSubmit} 
        />
      )
      break
    case 1:
      return (
        <Member
          initialValues={memberState}
          memberType={requestorState.type}
          isLoading={validatedMember.isLoading}
          validateResults={validatedMember.data}
          isError={validatedMember.isError}
          error={validatedMember.error?.response.text}
          onSubmit={handleSubmit} 
        />
      )
    case 2:
      return (
        <ContactInfo
          initialValues={contactInfoState}
          isLoading={sendMemberCode.isLoading}
          isError={sendMemberCode.isError}
          error={sendMemberCode.error?.response.text}
          onSubmit={handleSubmit} 
        />
      )
    case 3:
      return (
        <ValidateEmail
          memberDetails={contactInfoState}
          validateResults={validateEmail.data}
          isLoading={validateEmail.isLoading}
          isError={validateEmail.isError}
          error={validateEmail.error?.response.text}
          sendNewCode={handleSendNewCode}
          sendNewCodeResult={sendMemberCode}
          goBack={handleGoBackToContactStep}
          onSubmit={handleSubmit} 
        />
      )
    case 4:
      return (
        <CreateAccount 
          initialValues={createAccountState}
          isLoading={createMember.isLoading}
          isError={createMember.isError}
          error={createMember.error?.response.text}
          onSubmit={handleSubmit}
        />
      )
    default:
      return 'bad step passed through'
      break
    }
  }

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: '840px',
        margin: '1em auto',
      }}
    >
      {
        createMember.isSuccess ? (
          <div>
            <div className='bannerWithHelp'>
              <h2>Create Account</h2>
              <h3><Link to='/help'>Need Help?</Link></h3>
            </div>
            <Alert variant='success'>
              Success! Your account has been created. You can now <Alert.Link href='/login' className='text-decoration-underline'>Log In</Alert.Link>
            </Alert>
          </div>
        ): (
          <div>
            <div className='bannerWithHelp'>
              <h2>
                {
                  activeStep < 4 ? 'Register' : 'Create Account'
                }
              </h2>
              {
                activeStep < 4 && (
                  <p className='steps'>Step {activeStep + 1} of 4</p>
                )
              }
              <h3><Link to='/help'>Need Help?</Link></h3>
            </div>
            {
              activeStep === 0 && (
                <p>
                  <strong>Hello! </strong>You are registering for <strong>App Connect</strong>, which allows you to connect your health information to apps in the marketplace. First, tell us who you are:
                </p>
              )
            }
            {
              renderRegistrationStep(activeStep)
            }
          </div>
        )
      }
    </Container>
  )
}

export default Register
