import React, { FC } from 'react'
import { buildPatientId, buildPatientName } from '../../../utils/fhirUtils/patient'
import ObservationDetail from './ObservationDetail'
import ObservationList from './ObservationList'

interface Props {
  observationData: any;
  patientData: any;
  observationDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
}

const Observations: FC<Props> = ({ observationData, patientData, observationDetailId, page, isFetching }: Props) => {
  
  return (
    <div>
      <h3>{observationDetailId ? 'Observation Detail' : 'Observations'}: {patientData ? buildPatientName(patientData.name) : ''} {buildPatientId(patientData)}</h3>
      {
        observationDetailId && page === 'Observations' ? (
          <ObservationDetail observationData={observationData} patientData={patientData} detailId={observationDetailId} />
        ) : <ObservationList patientData={patientData} observationData={observationData} isFetching={isFetching} />
      }
    </div>
  )
}

export default Observations