import React, { FC, useEffect, useState } from 'react'
import { Breadcrumb, Button, Container, Form, Spinner, Table, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useMembers from '../../../hooks/admin/useMembers'
import isAuthenticated from '../../../routes/PrivateRoute'
import { calculateAge } from '../../../utils/helpers'

import './index.css'

const Members: FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  
  const [page, setPage] = useState(0)

  const { isLoading, data, isPreviousData, refetch, isSuccess, isFetching } = useMembers(searchTerm, page)

  const handleMemberSearch = (e: any) => {
    e.preventDefault()
    refetch()
  }

  const handleNextPage = () => {
    setPage((prevPage) => prevPage += 1)
  }

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage -= 1)
  }

  useEffect(() => {
    if (page) {
      refetch()
    }
  }, [page])

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item href='/admin'>Admin Home</Breadcrumb.Item>
        <Breadcrumb.Item active aria-current="page">Members</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Members</h2>
      <Form noValidate onSubmit={handleMemberSearch} className='form-inline'>
        <Form.Group>
          <Form.Label style={{ margin: '0 1em 0 0' }}>Find a Member</Form.Label>
          <Form.Control
            style={{ margin: '0 1em 0 0' }}
            type='text'
            name='searchTerm'
            placeholder="Type Member ID or Name"
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
        <Button className='searchBtn' type='submit'>
          Search
        </Button>
      </Form>
      <Table hover size='sm' className='mt-3'>
        <thead>
          <tr>
            <th scope="col" colSpan={5}>Member and Plan</th>
            <th scope="col" colSpan={1}>FHIR Data</th>
            <th scope="col" colSpan={3}>App Connect Account</th>
          </tr>
          <tr>
            <th scope="col">Member ID</th>
			      <th scope="col">Name</th>
			      <th scope="col">Age</th>
			      <th scope="col">Plan</th>
			      <th scope="col">Status</th>
			      <th scope="col">FHIR Data</th>
			      <th scope="col">Account</th>
            <th scope='col'>Status</th>
          </tr>
        </thead>
        <tbody>
          {
            isLoading || isFetching ? ( 
              <tr> 
			          <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                  <Spinner 
                    as='span'
                    animation='border'
                    role='status'
                    aria-hidden='true'
                  />
                </td>
			        </tr>
            ) :
              isSuccess && data?.members.length > 0 ? (
                <>
                  {
                    data.members.map((member: any) => (
                      <tr key={member.memberId}>
                        <td>{member.fhirPatientId}</td>
                        <td>{`${member.firstName || ''} ${member.lastName || ''}`}</td>
                        <td>{calculateAge(member.dateOfBirth)}</td>
                        <td></td>
                        <td></td>
                        <td><Link to={`/patient/${member.fhirPatientId}`}>View Data</Link></td>
                        <td>
                          {
                            member.hasAccount && (
                              <Link to={`/account/${member.accountId}`}>Manage Account</Link>
                            )
                          }
                        </td>
                        <td>
                          {
                            member.hasAccount ? 
                              member.enabled ? 'Active' : 'Inactive' : ''
                          }
                        </td>
                      </tr>
                    ))
                  }
                </>
              ) : (
                <>
			          { data?.members &&
                  <tr>
                    <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                      <span>Member Not found!</span>
                    </td>
                  </tr>
                  }
                </>
              )
          }
			  </tbody>
        <tfoot>
          <tr>
            <td scope="row" colSpan={8}>
              <Button
                variant='light'
                disabled={page === 0}
                onClick={handlePrevPage}
              >
                {'<'} Back
              </Button>
              <Button
                className='custom'
                disabled={!data?.hasMore}
                onClick={handleNextPage}
              >
                Next {'>'}
              </Button>
            </td>
          </tr> 
        </tfoot>
      </Table>
    </Container>
  )
}

export default isAuthenticated(Members)