import React, { FC } from 'react'
import Routes from './routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import 'bootstrap/dist/css/bootstrap.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import './App.css'

const queryClient = new QueryClient()

const App: FC = () => {
  console.log(process.env.REACT_APP_ENVIRONMENT)
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Routes />
        {
          process.env.REACT_APP_ENVIRONMENT === 'development' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )
        }
      </QueryClientProvider>
      
    </div>
  )
}

export default App
