import React, { FC, useState } from 'react'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { ContactInfoSchema } from '../../../utils/validation'

import './index.css'

interface Props {
    initialValues: any;
    isLoading: boolean;
    isError: boolean;
    error: string;
    onSubmit: (values: any) => void;
}

const ContactInfo: FC<Props> = ({ initialValues, isLoading, onSubmit }: Props) => {
  const [showError, setShowError] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: ContactInfoSchema,
    onSubmit: (values) => {
      onSubmit(values)
    },
    enableReinitialize: true
  })

  return (
    <>
      <p className="text-info">Please enter an email to use with App Connect. We will send a verification to continue with registration.</p>	
      <Form noValidate onSubmit={formik.handleSubmit} id='contactInfo'>
        <h4>Email</h4>
        <div>
          {
            showError && (
              <Alert variant='danger' dismissible onClose={() => setShowError(false)}>
              There was an error
              </Alert>
            )
          }
          <Form.Group>
            <Form.Control
              name='email'
              type='text'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.email && !!formik.touched.email}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
                
        </div>
        <Link to='/register/member' className='btn btn-light mr-3'>
        &lt;&lt; Back
        </Link>
        <Button className='memberSubmitBtn' type='submit'>
          {
            isLoading ? (
              <Spinner 
                as='span'
                animation='border'
                role='status'
                size='sm'
                aria-hidden='true'
              />
            ) : 'Next >>'
          }
        </Button>
      </Form>
    </>
  )
}

export default ContactInfo
