import request from 'superagent'
import { useMutation } from 'react-query'
import { getApiServer } from '../../utils/domain'

interface HTTPError extends Error {
  status?: number;
  response?: any;
}

export default function useValidateEmail() {
  return useMutation(
    (values: any) => request.post(`${getApiServer()}/validate-email`).set('Accept', 'application/json').send(values).then((res) => res.body), {
      onError: (error: HTTPError) => {
        return error.response.text
      }
    }
  )
}