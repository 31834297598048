import React, { FC } from 'react'
import {  formatImmunizationDataForTable } from '../../../utils/fhirUtils/immunization'
import { buildPatientId, buildPatientName } from '../../../utils/fhirUtils/patient'
import ImmunizationList from './ImmunizationList'
import ImmunizationDetail from './ImmunizationDetail'

interface Props {
  immunizationData: any;
  patientData: any;
  immunizationDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
}

const Immunizations: FC<Props> = ({immunizationData, patientData, immunizationDetailId, page, isFetching }: Props) => {
  const patientImmunizations = formatImmunizationDataForTable(immunizationData, buildPatientId(patientData))
  return (
    <div>
      <h3>{immunizationDetailId ? 'Immunization Detail' : 'Immunizations'}: {patientData ? buildPatientName(patientData.name) : ''} {buildPatientId(patientData)}</h3>
      {
        immunizationDetailId && page === 'Immunizations' ? (
          <ImmunizationDetail detailId={immunizationDetailId} immunizationData={immunizationData} patientData={patientData} isFetching={isFetching} />
        ) : <ImmunizationList patientImmunizations={patientImmunizations} patientData={patientData} isFetching={isFetching} />
      }
    </div>
  )
}
export default Immunizations