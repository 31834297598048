import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { formatDateInTimeZone, formatDollar } from '../../../../utils/helpers'

interface Props {
    patientEob: any;
}

const EobPharmItem: FC<Props> = ({ patientEob }: Props) => {
  const items = patientEob.resource.item
  const totals = patientEob.resource.total
  const adjudicationValuesArray: { [x: string]: any }[] = []
  const totalValuesArray: { [x: string]: any }[] = []

  items.forEach((item: any) => {
    const adjudicationValues: { [x: string]: any } = [
      {
        productOrService : '',
        servicedDate : '',
        sequence : ''
      },
      {
        submitted : ''
      }
    ]
    if (item.adjudication) {
      Object.keys(adjudicationValues[1]).forEach((adjKey: any) => {
        adjudicationValues[1][adjKey] = getAdjudicationValues(item, adjKey)
      })
    }
    Object.keys(adjudicationValues[0]).forEach((adjKey: any) => {
      adjudicationValues[0][adjKey] = getServiceValues(item, adjKey)
    })
    adjudicationValuesArray.push(adjudicationValues)
  })


  function getAdjudicationValues(adjItem: any, key: any) {
    const convertedKey = convertKey(key)
    return patientEob.resource?.item[0]?.adjudication.map((codableConcept: any) => codableConcept.value )
  }

  function getServiceValues(item: any, key: any) {
    const convertedKey = convertKey(key)
    
    if (convertedKey == 'productorservice') {
      return item.productOrService ? item.productOrService : ''
    } if (convertedKey == 'serviceddate') {
      return item.servicedDate ? item.servicedDate : ''
    } if (convertedKey == 'sequence') {
      return item.sequence ? item.sequence : ''
    }
  }

  function convertKey(key: any) {
    const result = key.replace(/_/g, ' ')
    const finalResult = result.toLowerCase()
    return finalResult
  }

  const totalValues: { [x: string]: any } = {
    submitted : ''
  }
  if (totals) {
    Object.keys(totalValues).forEach((adjKey: any) => {
      totalValues[adjKey] = getTotalValues(adjKey)
    })
  }
  totalValuesArray.push(totalValues)
  

  function getTotalValues( key: any) {
    const convertedKey = convertKey(key)
    return patientEob.resource?.total?.map((total: any) => total )
  }
  
  return (
    <>
      {
        adjudicationValuesArray.map((i: any) => (
          <div key={`item:${i.sequence}`}>
            <Card style={{ width: '100%' }} className='mt-3'>
              <Card.Header>
                <div>
                  <>
                    Procedure Code: {i[0].productOrService && i[0].productOrService.coding[0].code != undefined ? i[0]?.productOrService.coding[0]?.code : 'Code-N/A'} {i[0].productOrService.coding[0].display ? i[0].productOrService.coding[0].display : 'Display-N/A'}
                  </>
                </div>
              </Card.Header>
              <Card.Body>
                <>
                  Service Date: { i[0].servicedDate && i[0].servicedDate != undefined ? formatDateInTimeZone(i[0]?.servicedDate, 'MM/dd/yyyy', 'UTC') : 'N/A'}
                </>
                <div>
                  Adjudications: {i[1] && i[1] != undefined ? '' : 'N/A'}
                  <Row>
                    {i[1].submitted.map((b: any) => (
                      <Col xs={6} key={b}>
                        <Row>
                          <Col>
                          Submitted:
                          </Col>
                          <Col className='text-right'>
                            {b != undefined ? formatDollar(b) : 'N/A'}
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      <br></br>
    
      <div >
        <Card>
          <Card.Header>
            <div>
                  Total
            </div>
          </Card.Header>
          <Card.Body>
            <Row>
              { totalValuesArray[0]?.submitted.map((submitted: any) => (
                <Col xs={6} key={submitted.amount}>
                  <Row>
                    <Col>
                        Submitted:
                    </Col>
                    <Col className='text-right'>
                      {submitted && submitted.amount.value !=undefined ? formatDollar(submitted.amount.value) : 'N/A'}
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default EobPharmItem