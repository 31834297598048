import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'
import { getToken } from '../../utils/helpers'

export default function useMembers (searchTerm = '', page = 0): any {
  const token = getToken()
  return useQuery(['members', searchTerm, page], async () => {
    return await request.get(`${getApiServer()}/members`).query({ searchTerm, page }).set('Authorization', 'Bearer ' + token ).set('Accept', 'application/json').then((res) => res.body)
  }, {
    // initialData: null,
    staleTime: Infinity,
    cacheTime: 3600000,
    retry: 0,
    keepPreviousData: true,
    enabled: false
  })
}