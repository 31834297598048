import React, { useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import useUser from '../../hooks/authentication/useUser'
import { useHistory } from 'react-router-dom'

const isAuthenticated = (ComposedComponent: any) => {
  const Authenticated = (props: any) => {
    const { isStale, refetch, data, status } = useUser()

    const history = useHistory()
    useEffect(() => {
      if (data) { 
        if (data.role.find((a: string[]) => a.includes('admin')) && window.location.pathname === '/') {
          history.push('/admin')
        }
      }
    }, [data])

    useEffect(() => {
      if (isStale) {
        refetch()
      }
    }, [isStale])

    return (
      <>
        {
          status === 'loading' && (
            <div style={{ height: 10000 }} />
          )
        }
        {
          status === 'error' && (
            <Redirect to='/login' />
          )
        }
        {
          status === 'success' ? data.enabled ? (
            <ComposedComponent {...props} />
          ) : <Redirect to='/access-denied' />
            : null
        }
      </>
    )
  }

  return withRouter(Authenticated)
}

export default isAuthenticated