import React, { FC, useState } from 'react'
import { useEffect } from 'react'
import { Alert, Breadcrumb, Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useAccount from '../../../hooks/admin/useAccount'
import { calculateAge, formatDate, formatDateWithFormat } from '../../../utils/helpers'
import isAuthenticated from '../../../routes/PrivateRoute'
import useSaveAccount from '../../../hooks/admin/useSaveAccount'
import useForgotLogin from '../../../hooks/authentication/useForgotLogin'

interface RouteMatchParams {
  accountId: string;
}

const Account: FC = () => {
  const [alertState, setAlertState] = useState({
    show: false,
    variant: 'info',
    message: ''
  })
  const { accountId } = useParams<RouteMatchParams>()
  const { data: account, refetch: refetchAccount } = useAccount(accountId)
  const updateAccount = useSaveAccount(accountId)

  useEffect(() => {
    refetchAccount()
  }, [])

  useEffect(( ) => {
    if (updateAccount.isSuccess) {
      setAlertState({
        show: true,
        variant: 'success',
        message: 'Successfully updated account'
      })
    }

    if (updateAccount.isError) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: 'Unable to update account: '
      })
    }
  }, [updateAccount.isSuccess, updateAccount.isError])

  const handleUpdateAccount = () => {
    updateAccount.mutate({
      enabled: !account.enabled
    })
  }

  const forgotLogin = useForgotLogin()
  const handlePasswordReset = () => {
    forgotLogin.mutate({
      email: account.email,
      type: 'Password',
      memberId: account?.attributes.memberId[0]
    })
  }

  useEffect(( ) => {
    if (forgotLogin.isSuccess) {
      setAlertState({
        show: true,
        variant: 'success',
        message: 'Successfully requested user to reset password.'
      })
    }

    if (forgotLogin.isError) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: 'Unable to send Password Reset: '
      })
    }
  }, [forgotLogin.isSuccess, forgotLogin.isError])

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item href='/admin'>Admin Home</Breadcrumb.Item>
        <Breadcrumb.Item href='/accounts'>Member Accounts</Breadcrumb.Item>
        <Breadcrumb.Item active>Member Account</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col sm={6}>
          <h2>Member Account</h2>
        </Col>
        <Col sm={12}>
          <Alert show={alertState.show} variant={alertState.variant} dismissible onClose={() => setAlertState((ps) => ({ ...ps, show: false }))}>
            {alertState.message}
            {
              updateAccount.isError && (<div dangerouslySetInnerHTML={{ __html: updateAccount.error.response.text }} />)
            }
          </Alert>
          <section className='dataContainer'>
            <dl>
              <Row>
                <Col sm={3}>
                  <dt>Account Owner Name</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {
                      account?.attributes.accountOwner[0]
                    }
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Account Owner Type</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {account?.attributes.accountOwnerType[0]}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Email</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {account?.email}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Member Name</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {account?.firstName} {account?.lastName}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Member ID</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {account?.attributes.memberId ? account?.attributes.memberId[0] : ''}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Member Age</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {account?.attributes.dateOfBirth ? calculateAge(account?.attributes.dateOfBirth[0]) : ''}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Username</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {account?.username}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Reset Password</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    <Button className='custom' onClick={handlePasswordReset} disabled={forgotLogin.isLoading}>
                      {
                        forgotLogin.isLoading ? (
                          <Spinner 
                            as='span'
                            animation='border'
                            role='status'
                            size='sm'
                            aria-hidden='true'
                          />
                        ) : 'Reset Password'
                      }
                    </Button>
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Date Created</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {formatDate(account?.createdTimestamp)}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Last Login</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {formatDateWithFormat(account?.lastLoginTime, 'MM/dd/yyyy hh:mm')}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Account Status</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {account?.enabled ? 'Active' : 'Inactive'}
                    <Button
                      variant={account?.enabled ? 'danger' : 'success'}
                      className='ml-5'
                      onClick={handleUpdateAccount}
                      disabled={updateAccount.isLoading}
                    >
                      {
                        updateAccount.isLoading ? (
                          <Spinner 
                            as='span'
                            animation='border'
                            role='status'
                            size='sm'
                            aria-hidden='true'
                          />
                        ) : account?.enabled ? 'Inactivate Account' : 'Activate Account'
                      }
                    </Button>
                  </dd>
                </Col>
              </Row>
            </dl>
          </section>
        </Col>
      </Row>
    </Container>
  )
}

export default isAuthenticated(Account)