import { useMutation, useQueryClient } from 'react-query'
import request from 'superagent'
import ThirdPartyApp from '../../types/ThirdPartyApp'
import { getApiServer } from '../../utils/domain'

export default function useSaveApp (): any {
  const queryClient = useQueryClient()
  return useMutation(
    (values: ThirdPartyApp) => request.put(`${getApiServer()}/app/${values.appId}`).set('Accept', 'application/json').send(values).then((res) => res.body),
    {
      onMutate: (values) => {
        queryClient.setQueryData(['app', values.appId.toString()], values)
      },
      onSuccess: async (values) => {
        await queryClient.refetchQueries(['app', values.appId.toString()])
      }
    }
  )
}