import { capitalizeFirstLetter, formatDateInTimeZone } from '../helpers'
  
export function buildEOBClaimId (resource: any): string {
  return resource ? resource.id : ''
}
  
export function buildEOBStatus (resource: any): string {
  const eobStatus = resource ? resource.status : ''
  return capitalizeFirstLetter(eobStatus)
}
  
export function buildEOBUse (resource: any): string {
  return resource ? capitalizeFirstLetter(resource.use) : ''
}

export const buildEOBBillableStart = (resource: any) => resource.billablePeriod ? formatDateInTimeZone(resource.billablePeriod.start, 'MM/dd/yyyy', 'UTC') : ''

export const buildEOBBillableEnd = (resource: any) => resource.billablePeriod ? formatDateInTimeZone(resource.billablePeriod.end, 'MM/dd/yyyy', 'UTC') : ''

export const buildEOBCreatedDate = (resource: any) => resource?.created ? formatDateInTimeZone(resource.created, 'MM/dd/yyyy', 'UTC') : ''
  
export function buildEOBInsurer (resource: any): string {
  return resource?.insurer ? resource.insurer.reference : ''
}

export function buildEOBProvider (resource: any): string {
  return resource?.provider ? resource.provider.reference : ''
}
  
export function buildEOBRelated (resource: any): Array<string> {
  if (resource.related) {
    const eobRelated = resource.related
    const eobRelatedData = []
    for (let i = 0; i < eobRelated.length; i++) {
      const eobRelatedRelationship = resource ? eobRelated[i].relationship : ''
      const eobRelatedReference = resource ? eobRelated[i].reference : ''
      eobRelatedData.push(eobRelatedRelationship, eobRelatedReference)
    }
    return eobRelatedData
  }
  return []
}
  
export function buildEOBPayee (resource: any): string {
  return `${resource?.payee.party.reference}`
}
  
export function buildEOBOutcome (resource: any): string {
  return resource ? resource.outcome : ''
}

export const buildEobType = (resource: any) => resource ? resource.type.coding[0].display : ''

export const formatEobDataForTable = (eobs: any, memberId: string) => {
  if (!eobs) return []
  const patientEobs = eobs && eobs.entry ? eobs.entry.map((eob: any) => eob.resource) : []
  return patientEobs.map((patientEob: any) => {
    return ({
      eobId: patientEob.id,
      memberId,
      claimId: patientEob.id,
      type: patientEob.type.coding[0].display,
      billableStart: patientEob.billablePeriod ? patientEob.billablePeriod.start : '',
      billableEnd: patientEob.billablePeriod ? patientEob.billablePeriod.end : ''
    })
  })
}

export const buildEOBDiagnosis = (diagnosis: any) => {
  if (!diagnosis) return ''
  const eobDiagnosis = diagnosis.map((d: any) => d.diagnosisCodeableConcept.coding[0].code)
  return eobDiagnosis.join(', ')
}
  