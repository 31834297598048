import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import { useFormik } from 'formik'
import { CreateAccountSchema } from '../../../utils/validation'

import './index.css'

interface InitialState {
  username: string;
  password: string;
  passwordAgain: string;
}

interface Props {
    onSubmit: (values: any) => void;
    isLoading: boolean;
    isError: boolean;
    error: string;
    initialValues: InitialState;
}

const CreateAccount: FC<Props> = ({ initialValues, onSubmit, isLoading, isError, error }: Props) => {
  const [alertState, setAlertState] = useState({
    variant: 'success',
    show: true,
    message: 'The information you entered has been verified in our system and we have verified your email for notifications. Now you can create an account'
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: CreateAccountSchema,
    onSubmit: (values) => {
      onSubmit(values)
    }
  })

  useEffect(() => {
    if (isError) {
      setAlertState({
        variant: 'danger',
        show: true,
        message: error
      })
    }
  }, [isError])

  return (
    <Form noValidate onSubmit={formik.handleSubmit} id='account'>
      {
        alertState.show && (
          <Alert variant={alertState.variant} dismissible onClose={() => setAlertState((ps) => ({ ...ps, show: false }))}>
            {alertState.message}
          </Alert>
        )
      }
      <Form.Group>
        <Form.Label>Username:</Form.Label>
        <Form.Control 
          type='text' 
          name='username' 
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.username && formik.touched.username}
          placeholder='Create New Username'
          autoComplete='username'
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.username}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Password:</Form.Label>
        <Form.Control 
          type='password' 
          name='password' 
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.password && formik.touched.password}
          placeholder='Create Password'
          autoComplete='new-password'
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.password}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Enter Password Again:</Form.Label>
        <Form.Control 
          type='password' 
          name='passwordAgain' 
          value={formik.values.passwordAgain}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.passwordAgain && formik.touched.passwordAgain}
          placeholder='Repeat Password'
          autoComplete='new-password'
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.passwordAgain}
        </Form.Control.Feedback>
      </Form.Group>
      <Button className='memberSubmitBtn' type='submit' disabled={isLoading}>
        {
          isLoading ? (
            <Spinner 
              as='span'
              animation='border'
              role='status'
              size='sm'
              aria-hidden='true'
            />
          ) : 'Create Account'
        }
      </Button>
    </Form>
  )
}

export default CreateAccount