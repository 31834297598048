import { capitalizeFirstLetter, formatDateInTimeZone } from '../helpers'
import PatientName from '../../types/PatientName'
import PatientAddress from '../../types/PatientAddress'
import PatientTelecom from '../../types/PatientTelecom'

export function buildPatientName (patientNames: Array<PatientName>): string {
  if (patientNames) {
    const officialName = patientNames.find((patientName: PatientName) => patientName.use === 'official')
    let familyName
    let givenName
    if (officialName) {
      familyName = officialName?.family || ''
      givenName = officialName?.given.join(' ')
    } else {
      const { family, given } = patientNames[0]
      familyName = family || ''
      givenName = given.join(' ')
    }
    return `${givenName} ${familyName}`
  }
  return ''
}
  
export function buildPatientGeneralPractitioner (patientPractitioner: any): string {
  return patientPractitioner && patientPractitioner[0].reference ? patientPractitioner[0].reference : ''
}


export function buildPatientAddress (patientAddresses: Array<PatientAddress>): Array<{ use: string; address: string }> {
  if (!patientAddresses) return []
  return patientAddresses.map((patientAddress) => {
    if (patientAddress.text) {
      return ({ use: patientAddress.use, address: patientAddress.text })
    }
    const address = `${patientAddress.line.join(' ')} ${patientAddress.city || ''}, ${patientAddress.state || ''} ${patientAddress.postalCode || ''}`

    return ({
      use: patientAddress.use || '',
      address
    })
  })
}
  
export function buildPatientBirthday (resource: any): string {
  return resource && resource.birthDate ? formatDateInTimeZone(resource.birthDate, 'MM/dd/yyyy', 'UTC') : ''
}
  
export function buildPatientTelecom (patientTelecoms: Array<PatientTelecom>, use: string): Array<string | undefined> {
  if (!patientTelecoms) return []
  const telecoms = patientTelecoms.filter((patientTelecom) => patientTelecom.system === use)

  return telecoms.map((patientTelecom) => {
    if (patientTelecom.value) {
      return `${capitalizeFirstLetter(patientTelecom.use)}: ${patientTelecom.value}`
    }
  }) 
}

export function patientHasEmail (patientTelecoms: Array<PatientTelecom>): boolean {
  let hasEmail = false
  if (!patientTelecoms) return hasEmail
  const email = patientTelecoms.find((patientTelecom) => patientTelecom.system === 'email')
  if (email) {
    hasEmail = true
  }
  return hasEmail
}
  
export function buildPatientId (resource: any): string {
  return resource ? resource.id : ''
}
  
export function buildPatientStatus (resource: any): string {
  const patientActive = resource ? resource.active : null
  return patientActive ? 'Yes' : 'No'
}
  
export function buildPatientGender (resource: any): string {
  const patientGender = resource ? resource.gender : null
  return patientGender ? capitalizeFirstLetter(patientGender) : ''
}
  