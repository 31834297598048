import React, { FC } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { buildPatientId } from '../../../../utils/fhirUtils/patient'
import { formatDateInTimeZone } from '../../../../utils/helpers'

interface Props {
    detailId: string;
    immunizationData: any;
    patientData: any;
    isFetching: boolean;
}

const ImmunizationDetail: FC<Props> = ({ detailId, immunizationData, patientData, isFetching }: Props) => {
  const immunization = immunizationData && immunizationData.entry ? immunizationData.entry.find((i: any) => i.resource.id === detailId) : ''
  return (
    <section>
      {
        isFetching ? (
          <tr>
            <td colSpan={6} rowSpan={15} className='d-flex'>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
                    Loading Member Immunizations...
            </td>
          </tr>
        ) : immunizationData && immunizationData.entry ? (
          <dl className='dataContainer'>
            <Row>
              <Col sm={3}>
                <dt>Member ID</dt>
              </Col>
              <Col sm={9}>
                {buildPatientId(patientData)}
              </Col>
              <Col sm={3}>
                <dt>Immunization ID</dt>
              </Col>
              <Col sm={9}>
                {detailId}
              </Col>
              <Col sm={3}>
                <dt>Occurence Date</dt>
              </Col>
              <Col sm={9}>
                {formatDateInTimeZone(immunization.resource.occurrenceDateTime, 'MM/dd/yyyy', 'UTC')}
              </Col>
              <Col sm={3}>
                <dt>Vaccine</dt>
              </Col>
              <Col sm={9}>
                {immunization.resource.vaccineCode.coding[0].code} - {immunization.resource.vaccineCode.coding[0].display} ({immunization.resource.vaccineCode.text})
              </Col>
              <Col sm={3}>
                <dt>Status</dt>
              </Col>
              <Col sm={9}>
                {immunization.resource.status}
              </Col>
              <Col sm={3}>
                <dt>Notes</dt>
              </Col>
              <Col sm={9}>
          
              </Col>
              <Col sm={3}>
                <dt>Resource URL</dt>
              </Col>
              <Col sm={9}>
                {immunization.fullUrl}
              </Col>
            </Row>
          </dl>
        ) : 
          <tr>
            <td colSpan={6} rowSpan={15} className='text-center'>
            No Immunizations Details found for this member.
            </td>
          </tr>
      }
    </section>
  )
}

export default ImmunizationDetail