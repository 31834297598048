import React, { FC, useState } from 'react'
import { useEffect } from 'react'
import { Breadcrumb, Button, Container, Form, Table, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import useAccounts from '../../../hooks/admin/useAccounts'
import useQuery from '../../../hooks/location/useQuery'
import isAuthenticated from '../../../routes/PrivateRoute'

import './index.css'

const Users: FC = () => {
  const history = useHistory()
  const query = useQuery()

  const accountOwnerTypeQuery = query.get('accountOwnerType')
  const [accountOwnerType, setAccountOwnerType] = useState('all')

  const accountStatusQuery = query.get('accountStatus')
  const [accountStatus, setAccountStatus] = useState('all')

  const [page, setPage] = useState(0)

  const { data: accounts, isLoading, refetch, isSuccess } = useAccounts(accountOwnerType, accountStatus, page)

  const handleNextPage = () => {
    setPage((prevPage) => prevPage += 1)
    refetch()
  }

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage -= 1)
    refetch()
  }

  useEffect(() => {
    if (accountOwnerTypeQuery) {
      setAccountOwnerType(accountOwnerTypeQuery)
    }

    if (accountStatusQuery) {
      setAccountStatus(accountStatusQuery)
    }

    refetch()
  }, [accountOwnerTypeQuery, accountStatusQuery])

  const updateAccountFilter = (event:any) => {
    if (event.target.name === 'accountOwnerType') {
      history.push(`/accounts?accountOwnerType=${event.target.value}&accountStatus=${accountStatus}`)
    }

    if (event.target.name === 'accountStatus') {
      history.push(`/accounts?accountOwnerType=${accountOwnerType}&accountStatus=${event.target.value}`)
    }

    setPage(0)
  }


  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item href='/admin'>Admin Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Member Accounts</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Member Accounts</h2>
      <Table size='sm' hover>
        <thead>
          <tr>
            <th scope='col'>&nbsp;</th>
            <th scope='col'>
              <Form.Control
                as='select'
                name='accountOwnerType'
                value={accountOwnerType}
                onChange={updateAccountFilter}
              >
                <option value='all'>All</option>
                <option value='Self'>Self</option>
                <option value='Parent/Guardian'>Parent/Guardian</option>
              </Form.Control>
            </th>
            <th scope='col' colSpan={2}>&nbsp;</th>
            <th scope='col'>
              <Form.Control
                as='select'
                name='accountStatus'
                value={accountStatus}
                onChange={updateAccountFilter}
              >
                <option value='all'>All</option>
                <option value='active'>Active</option>
                <option value='inactive'>Inactive</option>
              </Form.Control>
            </th>
            <th scope='col' colSpan={3}>&nbsp;</th>
          </tr>
          <tr>
            <th scope="col">Account Owner Name</th>
            <th scope="col">Account Owner Type</th>
            <th scope="col">Member Name</th>
            <th scope="col">Member ID</th>
            <th scope="col">Account Status</th>
            <th scope="col">View Detail</th>
          </tr>
        </thead>
        <tbody>
          {
            isSuccess && accounts.accounts.length > 0 ? (
              <>
                {
                  accounts.accounts.map((account: any) => (
                    <tr key={account.id}>
                      <td>
                        {
                          account.attributes.accountOwner && account.attributes.accountOwner[0]
                        }
                      </td>
                      <td>
                        {
                          account.attributes.accountOwnerType && account.attributes.accountOwnerType[0]
                        }
                      </td>
                      <td>
                        {account.firstName} {account.lastName}
                      </td>
                      <td>
                        {
                          account.attributes.memberId && account.attributes.memberId[0]
                        }
                      </td>
                      <td>
                        {
                          account.enabled ? 'Active' : 'Inactive'
                        }
                      </td>
                      <td>
                        <Link to={`/account/${account.id}`}>View Details</Link>
                      </td>
                    </tr>
                  ))
                }
              </>
            ) : isLoading ? (
              <tr>
			          <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                  <Spinner 
                    as='span'
                    animation='border'
                    role='status'
                    aria-hidden='true'
                  />
                </td>
			        </tr>
            ) : (
              <tr>
			            <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                  <span>No Data found!</span>
                </td>
			        </tr>
            )
          }
			    </tbody>
        <tfoot>
          <tr>
            <td scope="row" colSpan={8}>
              <Button
                variant='light'
                disabled={page === 0}
                onClick={handlePrevPage}
              >
                {'<'} Back
              </Button>
              <Button
                className='custom'
                disabled={!accounts?.hasMore}
                onClick={handleNextPage}
              >
                Next {'>'}
              </Button>
            </td>
          </tr> 
        </tfoot>
      </Table>
    </Container>
  )
}

export default isAuthenticated(Users)