import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { buildPatientId } from '../../../../utils/fhirUtils/patient'
import { formatDateInTimeZone } from '../../../../utils/helpers'

interface Props {
    patientImmunizations: any;
    patientData: any;
    isFetching: boolean;
}

const ImmunizationList: FC<Props> = ({ patientData, patientImmunizations, isFetching }: Props) => {
  return (
    <Table size='sm' hover>
      <thead>
        <tr>
          <th scope="col">Member ID</th>
          <th scope="col">Immunization ID</th>
          <th scope="col">Date</th>
          <th scope="col">Vaccine</th>
          <th scope="col">View Detail</th>
        </tr>
      </thead>
      <tbody>
        {
          isFetching ? (
            <tr>
              <td colSpan={6} rowSpan={15} className='d-flex'>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
                    Loading Member Immunizations...
              </td>
            </tr>
          ) : patientImmunizations.length === 0 ? (
            <tr>
              <td colSpan={6} rowSpan={15} className='text-center'>
                  No Immunizations found for this member.
              </td>
            </tr>
          ) : (
            <>
              {
                patientImmunizations.map((immunization: any) => (
                  <tr key={immunization.immunizationId}>
                    <th scope="row">{immunization.memberId}</th>
                    <td scope='col'>{immunization.immunizationId}</td>
                    <td scope='col'>{formatDateInTimeZone(immunization.occurrenceDate, 'MM/dd/yyyy', 'UTC')}</td>
                    <td scope='col'>{immunization.vaccine}</td>
                    <td scope='col'><Link to={`/patient/${buildPatientId(patientData)}/Immunizations/${immunization.immunizationId}`}>View Detail</Link></td>
                  </tr>
                ))
              }
            </>
          )
          
        }
      </tbody>
			  
    </Table>
  )
}

export default ImmunizationList