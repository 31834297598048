import { sub } from 'date-fns'
import * as Yup from 'yup'
import { isValidUrl } from './helpers'

const numbersOnlyRegex = /^\d+$/
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/
const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const MemberSchema = Yup.object().shape({
  memberType: Yup.string(),
  memberId: Yup.string().required('This field is required.'),
  dob: Yup.date()
    .required('This field is required.')
    .when(
      'memberType', {
        is: 'parent',
        then: Yup.date().typeError('Please specify a valid date.').min(sub(new Date(), { years: 18 }), 'Member is over 18 and can/should register for their own account.'),
        otherwise: Yup.date().typeError('Please specify a valid date.').max(sub(new Date(), { years: 18 }), 'Members must be at least 18 years old to use App Connect. Parents or guardians of members under the age of 18 can set up accounts on behalf of their minor children.'),
      }
    ),
  ssn: Yup.string().matches(numbersOnlyRegex, 'This field should have digits only.').length(4, 'This field requires 4 digits.').required('This field is required.')
})

export const RequestorSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required.'),
  lastName: Yup.string().required('This field is required.'),
  type: Yup.string().required('Please select whether you are the Member or the Parent/Legal Guardian.'),
})

export const ContactInfoSchema = Yup.object().shape({
  email: Yup.string().email('Please specify a valid email.').required('This field is required.')
})

export const CreateAccountSchema = Yup.object().shape({
  username: Yup.string().required('This field is required.'),
  password: Yup.string().required('This field is required.').matches(passwordRegex, 'Password must be min 8 characters, and have 1 Uppercase, 1 Number and 1 Lowercase'),
  passwordAgain: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match.').required('This field is required.')
})

export const LoginSchema = Yup.object().shape({
  username: Yup.string().required('This field is required.'),
  password: Yup.string().required('This field is required.')
})

export const ForgotSchema = Yup.object().shape({
  memberId: Yup.string().required('This field is required.'),
  email: Yup.string().email('Please specify a valid email.').required('This field is required.')
})

export const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string().required('This field is required.').matches(passwordRegex, 'Password must be min 8 characters, and have 1 Uppercase, 1 Number and 1 Lowercase'),
  passwordAgain: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match.').required('This field is required.')
})

export const AppRegisterSchema = Yup.object().shape({
  readCarin: Yup.boolean().oneOf([true], 'Please review and agree to the Carin Code of Conduct.'),
  companyName: Yup.string().required('This field is required.'),
  address1: Yup.string().required('This field is required.'),
  address2: Yup.string().nullable(),
  city: Yup.string().required('This field is required.'),
  state: Yup.string().required('This field is required.'),
  zip: Yup.string().matches(zipRegex, 'Please provide a valid zip/postal code').required('This field is required.'),
  url: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  appName: Yup.string().required('This field is required.'),
  appDescription: Yup.string().max(255, 'Character length is 255').required('This field is required.'),
  termsUrl: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  redirectUrl: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  iosAppId: Yup.string().required('This field is required.'),
  iosAppUrl: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  androidAppId: Yup.string().required('This field is required.'),
  androidAppUrl: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  contactName: Yup.string().required('This field is required.'),
  email: Yup.string().email('Please provide a valid email.').required('This field is required.'),
  phone: Yup.string().matches(phoneRegex, 'Please provide a valid phone number.').required('This field is required.')
})

export const ContactSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  email: Yup.string().email('Please specify a valid email.').required('This field is required.'),
  memberId: Yup.string().required('This field is required.'),
  message: Yup.string().required('This field is required.')
})

export const memberIdSchema = Yup.object().shape({
  memberId: Yup.string().required('Please Enter a Member ID'),
})