import React, { FC } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './index.css'

const Help: FC = () => {
  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: '820px'
      }}
    >
      <h2>Help</h2>
      <div className="support">
        <div>
          <h3>Can&apos;t locate Member ID?</h3>
          <h4>Medicaid and Care4Kids</h4>
          <p><a href="tel:+01-1-800-482-8010" rel="nofollow">1-800-482-8010</a></p>
          <p>7:30 am to 5:00 pm CST (M - Thurs) and Friday 8 am - 4:30 pm CST</p>
          <h4>Individual &amp; Family</h4>
          <p><a href="tel:+01-1-844-201-4672" rel="nofollow">1-844-201-4672</a></p>
          <p>8:00 am - 6:00 pm CST (M - F) and Saturday 8:00 am - 2:00 pm CST</p>
        </div>
        <div>
          <h3>App Connect Questions</h3>
          <p>
            For all other questions related to App Connect and Data Interoperability, please <a href="/contact">Contact App Connect Support.</a>
          </p>
        </div>
      </div>
      <h3>What is App Connect?</h3>

      <p>App Connect is a tool that allows you to connect your Chorus Community Health Plans (CCHP) or Care4Kids health information to third party health history apps.</p>

      <h3>Who can get an App Connect account?</h3>
      <p>If you are a current or previous member of CCHP or Care4Kids with a health record created after December 31, 2015, you are eligible for App Connect. <strong>Members must be at least 18 years old to use App Connect.</strong> Parents or guardians of members under the age of 18 can set up accounts on behalf of their minor children. <Link to="/register">Register here.</Link></p>

      <h3>How does App Connect work?</h3>
      <p>App Connect provides a link between CCHP or Care4Kids, and external apps.  Once you provide authorization in App Connect, you may use the approved application(s) to view your CCHP or Care4Kids  health information. These external apps may also include health information from other health plans you authorize.</p>
      <p>Data is exchanged between health plans and external applications using Health Level 7&reg; (HL7) Fast Healthcare Interoperability Resources&reg; (FHIR) Release 4.0.1 as the foundational standard to support data exchange via secure application programming interfaces (APIs).</p>

      <h3>What health information is shared through App Connect?</h3>
      <p>Once you authorize App Connect to share with an external app, that external app will be able to connect to your CCHP or Care4Kids health information. Examples of health information that will be shared include  medical claims, medications, immunizations, care plans, and coverage .  App Connect does not contain any health information from other health plans. The external apps may allow you to connect to other health plans you may have been enrolled with.</p>

      <h3>Is my health information safe?</h3>
      <p>CCHP and Care4Kids will not share your health information with any outside party without your consent.  CCHP and Care4Kids are not responsible for your health information once you authorize an external application access to it. CCHP and Care4Kids recommend reviewing the terms of use of any external app prior to granting them access to your health information. Only grant access to apps you trust. CCHP and Care4Kids also recommend limiting the number of external apps you grant access to in order to limit potential exposure.</p>

      <h3>Why was App Connect set up?</h3>
      <p>App Connect was created to meet the requirements of the Centers for Medicare & Medicaid Services Interoperability and Patient Access final rule (CMS-9115-F).  This rule is meant to give patients better access to their health information, leading to better care and improved outcomes.  For more information on Interoperability and Patient Access visit: <a href="https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet" target="_blank" rel="noreferrer">https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet</a>
      </p>

      <h3>Is App Connect the same as my Individual &amp; Family Plans Member Portal or my Wellness Portal account?</h3>

      <p>No, App Connect is completely separate from the Individual &amp; Family Plans Member Portal and Wellness Portal. App Connect requires a separate account and login information.</p>

      <h3>What are important things you should consider before authorizing a third-party app to retrieve your health care data?</h3>

      <p>It is important for you to take an active role in protecting your health information.  You should consider:</p>

      <ul>
        <li>What health data will this app collect? Will this app collect non-health data from my device,
          such as my location?</li>
        <li>Will my data be stored in a de-identified or anonymized form?</li>
        <li>How will this app use my data?</li>
        <li>Will this app disclose my data to third parties?
          <ul>
            <li>Will this app sell my data for any reason, such as advertising or research?</li>
            <li>Will this app share my data for any reason? If so, with whom? For what purpose?</li>
          </ul>
        </li>
        <li>How can I limit this app&apos;s use and disclosure of my data?</li>
        <li>What security measures does this app use to protect my data?</li>
        <li>What impact could sharing my data with this app have on others, such as my family members?</li>
        <li>How can I access my data and correct inaccuracies in data retrieved by this app?</li>
        <li>Does this app have a process for collecting and responding to user complaints?</li>
        <li>If I no longer want to use this app, or if I no longer want this app to have access to my health
          information, how do I terminate the app&apos;s access to my data?
        <ul>
          <li>What is the app&apos;s policy for deleting my data once I terminate access?</li>
          <li>Do I have to do more than just delete the app from my device?</li>
        </ul>
        </li>
        <li>How does this app inform users of changes that could affect its privacy practices?</li>
      </ul>
      <p>If the app&apos;s privacy policy does not clearly answer these questions, you should reconsider using the app to access your health information. Health information is very sensitive information, and you should be careful to choose apps with strong privacy and security standards to protect it.</p>

      <h3>What should I consider if I am part of an enrollment group?</h3>
      <p>You may be part of an enrollment group where you share the same health plan as multiple members of your tax household. Often, the primary policy holder and other members can access information for all members of an enrollment group unless a specific request is made to restrict access to member data. If you share a tax household but who do not want to share an enrollment group, you have the option of enrolling individual household members into separate enrollment groups, even while applying for Exchange coverage and financial assistance on the same application; however, this may result in higher premiums for the household and some members, (i.e. dependent minors,) may not be able to enroll in all QHPs in a service area if enrolling in their own enrollment group and in higher total out-of-pocket expenses if each member has to meet a separate annual limitation on cost sharing (i.e., Maximum Out-of-Pocket (MOOP)).</p>

      <h3>What are my rights under the Health Insurance Portability and Accountability Act (HIPAA) and who must follow HIPAA?</h3>
      <p>The U.S. Department of Health and Human Services (HHS) Office for Civil Rights (OCR) enforces the HIPAA Privacy, Security, and Breach Notification Rules, and the Member Safety Act and Rule. You can find more information about member rights under HIPAA and who is obligated to follow HIPAA here:  <a href="https://www.hhs.gov/hipaa/for-individuals/index.html">https://www.hhs.gov/hipaa/for-individuals/index.html</a> or here: <a href="https://www.hhs.gov/hipaa/for-individuals/faq/index.html">https://www.hhs.gov/hipaa/for-individuals/faq/index.html</a></p>

      <h3>Are third-party apps covered by HIPAA?</h3>
      <p>Most third-party apps will not be covered by HIPAA. Most third-party apps will instead fall under the
        jurisdiction of the Federal Trade Commission (FTC) and the protections provided by the FTC Act. The FTC
        Act, among other things, protects against deceptive acts (e.g., if an app shares personal data without
        permission, despite having a privacy policy that says it will not do so). The FTC provides information
        about mobile app privacy and security for consumers here: <a href="https://www.consumer.ftc.gov/articles/0018-understanding-mobile-apps">https://www.consumer.ftc.gov/articles/0018-understanding-mobile-apps</a></p>

      <h3>What should you do if you think your data has been breached or an app has used your data inappropriately?</h3>
      <p>You can file a complaint with OCR under HIPAA: <a href="https://www.hhs.gov/hipaa/filing-a-complaint/index.html">https://www.hhs.gov/hipaa/filing-a-complaint/index.html</a></p>
      <p>You can file a complaint with OCR using the OCR complaint portal: <a href="https://ocrportal.hhs.gov/ocr/smartscreen/main.jsf">https://ocrportal.hhs.gov/ocr/smartscreen/main.jsf</a></p>
      <p>You can file a complaint with the FTC using the FTC complaint assistant: <a href="https://reportfraud.ftc.gov/">https://reportfraud.ftc.gov/</a></p>

    </Container>
  )
}

export default Help