import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { formatDateInTimeZone, formatDollar } from '../../../../utils/helpers'

interface Props {
    patientEob: any;
}

const EobProfessionalItem: FC<Props> = ({ patientEob }: Props) => {
  const items = patientEob.resource.item
  const total = patientEob.resource.total
  const adjudicationValuesArray: { [x: string]: any }[] = []

  items.forEach((item: any) => {
    const adjudicationValues: { [x: string]: any } = [
      {
        productOrService : '',
        servicedDate : '',
        sequence : '',
        revenue : ''
      },
      {
        submitted : '',
        eligible : '',
        noncovered : '', 
        copay : '',
        deductible : '',
        member_liability : '',
        coinsurance : '',
        paid_to_provider : '',
        prior_payer_paid : '',
        discount : ''
      }
    ]
    if (item.adjudication) {
      Object.keys(adjudicationValues[1]).forEach((adjKey: any) => {
        adjudicationValues[1][adjKey] = getAdjudicationValues(item.adjudication, adjKey)
      })
    }
    Object.keys(adjudicationValues[0]).forEach((adjKey: any) => {
      adjudicationValues[0][adjKey] = getServiceValues(item, adjKey)
    })
    adjudicationValuesArray.push(adjudicationValues)
  })

  function getAdjudicationValues(adjItem: any, key: any) {
    const convertedKey = convertKey(key)
    return adjItem.find((codableConcept: any) => codableConcept.category.coding[0].code === convertedKey )
  }

  function getServiceValues(item: any, key: any) {
    const convertedKey = convertKey(key)
    
    if (convertedKey == 'productorservice') {
      return item.productOrService ? item.productOrService : ''
    } if (convertedKey == 'serviceddate') {
      return item.servicedDate ? item.servicedDate : ''
    } if (convertedKey == 'sequence') {
      return item.sequence ? item.sequence : ''
    } if (convertedKey === 'revenue') {
      return item.revenue ? item.revenue : ''
    }
  }

  function convertKey(key: any) {
    const result = key.replace(/_/g, ' ')
    const finalResult = result.toLowerCase()
    return finalResult
  }


  const totalValues: { [x: string]: any } = {
    submitted : '',
    eligible : '',
    noncovered : '', 
    copay : '',
    deductible : '',
    member_liability : '',
    coinsurance : '',
    paid_to_provider : '',
    prior_payer_paid : '',
    discount : ''
  }
  if (total) {
    Object.keys(totalValues).forEach((adjKey: any) => {
      totalValues[adjKey] = getTotalValues(adjKey)
    })
  }

  function getTotalValues( key: any) {
    const convertedKey = convertKey(key)
    return patientEob.resource?.total?.find((adjudication: any) => adjudication.category.coding[0].code === convertedKey )
  }
  
  return (
    <>
      {
        adjudicationValuesArray.map((i: any) => (
          <div key={`item:${i.sequence}`}>
            <Card style={{ width: '100%' }} className='mt-3'>
              <Card.Header>
                <div>
                  <>
                    Procedure Code: {i[0].productOrService && i[0].productOrService.coding[0].code ? i[0].productOrService.coding[0].code : 'Code-N/A'} {i[0].productOrService.coding[0].display ? i[0].productOrService.coding[0].display : 'Display-N/A'}
                  </>
                </div>
                <div>
                    Revenue Code: {i[0].revenue && i[0].revenue.coding[0]?.code != undefined ? i[0].revenue.coding[0].code : 'N/A'}
                </div>  
              </Card.Header>
              <Card.Body>
                <>
                  Service Date: { i[0].servicedDate != undefined ? formatDateInTimeZone(i[0].servicedDate, 'MM/dd/yyyy', 'UTC') : 'N/A'}
                </>
                <div>
                  Adjudications: {i[1] != undefined ? '' : 'N/A'}
                  <Row>
                    {<><Col xs={6}>
                      <Row>
                        <Col>
                          Submitted:
                        </Col>
                        <Col className='text-right'>
                          {i[1].submitted && i[1].submitted.value != undefined ? formatDollar(i[1].submitted.value) : 'N/A'}
                        </Col>
                      </Row> 
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col>
                            Eligible:
                        </Col>
                        <Col className='text-right'>
                          {i[1].eligible && i[1].eligible.value != undefined ? formatDollar(i[1].eligible.value) : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col>
                            Noncovered:
                        </Col>
                        <Col className='text-right'>
                          {i[1].noncovered && i[1].noncovered.value != undefined ? formatDollar(i[1].noncovered.value) : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col>
                            Copay:
                        </Col>
                        <Col className='text-right'>
                          {i[1].copay && i[1].copay.value != undefined ? formatDollar(i[1].copay.value) : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col>
                            Deductible:
                        </Col>
                        <Col className='text-right'>
                          {i[1].deductible && i[1].deductible.value != undefined ? formatDollar(i[1].deductible.value) : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col>
                            Member Liability:
                        </Col>
                        <Col className='text-right'>
                          {i[1].member_liability && i[1].member_liability.value != undefined ? formatDollar(i[1].member_liability.value) : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col>
                            Coinsurance:
                        </Col>
                        <Col className='text-right'>
                          {i[1].coinsurance && i[1].coinsurance.value != undefined ? formatDollar(i[1].coinsurance.value) : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col>
                            Paid To Provider:
                        </Col>
                        <Col className='text-right'>
                          {i[1].paid_to_provider && i[1].paid_to_provider.value != undefined ? formatDollar(i[1].paid_to_provider.value) : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col>
                            Prior Payer Paid:
                        </Col>
                        <Col className='text-right'>
                          {i[1].prior_payer_paid && i[1].prior_payer_paid.value != undefined ? formatDollar(i[1].prior_payer_paid.value) : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col>
                            Discount:
                        </Col>
                        <Col className='text-right'>
                          {i[1].discount && i[1].discount.value != undefined ? formatDollar(i[1].discount.value) : 'N/A'}
                        </Col>
                      </Row>
                    </Col>

                    </>}
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
    
      <br></br>
    
      <Card>
        <Card.Header>
          <div>
            Total
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            {<><Col xs={6}>
              <Row>
                <Col>
                Submitted:
                </Col>
                <Col className='text-right'>
                  {totalValues.submitted && totalValues.submitted.amount.value !=undefined ? formatDollar(totalValues.submitted?.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                Eligible:
                </Col>
                <Col className='text-right'>
                  {totalValues.eligible && totalValues.eligible.amount.value !=undefined ? formatDollar(totalValues?.eligible.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                Noncovered:
                </Col>
                <Col className='text-right'>
                  {totalValues.noncovered && totalValues.noncovered.amount.value !=undefined ? formatDollar(totalValues.noncovered.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                Copay:
                </Col>
                <Col className='text-right'>
                  {totalValues.copay && totalValues.copay.amount.value !=undefined ? formatDollar(totalValues.copay.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                Deductible:
                </Col>
                <Col className='text-right'>
                  {totalValues.deductible && totalValues.deductible.amount.value !=undefined ? formatDollar(totalValues.deductible.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                Member Liability:
                </Col>
                <Col className='text-right'>
                  {totalValues.member_liability && totalValues.member_liability.amount.value !=undefined ? formatDollar(totalValues.member_liability.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                Coinsurance:
                </Col>
                <Col className='text-right'>
                  {totalValues.coinsurance && totalValues.coinsurance.amount.value !=undefined ? formatDollar(totalValues.coinsurance.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                Paid To Provider:
                </Col>
                <Col className='text-right'>
                  {totalValues.paid_to_provider && totalValues.paid_to_provider.amount.value !=undefined ? formatDollar(totalValues.paid_to_provider.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                Prior Payer Paid:
                </Col>
                <Col className='text-right'>
                  {totalValues.prior_payer_paid && totalValues.prior_payer_paid.amount.value !=undefined ? formatDollar(totalValues.prior_payer_paid.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                Discount:
                </Col>
                <Col className='text-right'>
                  {totalValues.discount && totalValues.discount.amount.value !=undefined ? formatDollar(totalValues.discount?.amount.value) : 'N/A'}
                </Col>
              </Row>
            </Col>

            </>}
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default EobProfessionalItem