import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'
import { getToken } from '../../utils/helpers'

export default function useUser (): any {
  const token = getToken()
  return useQuery('user', async () => {
    const foundUser = await request.get(`${getApiServer()}/user`).set('Authorization', 'Bearer ' + token ).set('Accept', 'application/json')
    return foundUser.body
  }, {
    staleTime: 3600000,
    cacheTime: 3600000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 0,
    enabled: false
  })
}