import React, { FC } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { buildCoveragePeriod, buildCoverageStatus, buildCoverageIdentifier, buildCoverageSubId, buildCoveragePayer, buildCoverageBeneficiary, buildCoverageRelationship } from '../../../utils/fhirUtils/coverage'
import { buildPatientId, buildPatientName } from '../../../utils/fhirUtils/patient'
import { capitalizeFirstLetter, formatDateInTimeZone } from '../../../utils/helpers'

interface Props {
  coverageData: any;
  patientData: any;
  isFetching: boolean;
}

const Coverage: FC<Props> = ({coverageData, patientData, isFetching}: Props) => {
  const patientCoverages = coverageData && coverageData.entry ? coverageData.entry.map((coverage: any) => coverage.resource) : []
  return (
    <>
      <h3>Coverage: {patientData ? buildPatientName(patientData.name) : ''} {buildPatientId(patientData)}</h3>
      {
        isFetching ? (
          <Row>
            <Col className='d-flex'>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
                    Loading Member Coverage...
            </Col>
          </Row>
        ) : patientCoverages.length > 0 ? (
          patientCoverages.map((coverage: any) => (
            <dl className='dataContainer' key={coverage.id}>
              <Row>
                <Col sm={3}>
                  <dt>ID</dt>
                </Col>
                <Col sm={9}>
                  <dd>{coverage.id}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Coverage Identifier</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageIdentifier(coverage)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Status</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageStatus(coverage)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Subscriber ID</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageSubId(coverage)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Plan Beneficiary ID</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageBeneficiary(coverage)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Relationship</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageRelationship(coverageData)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Period Start</dt>
                </Col>
                <Col sm={9}>
                  <dd>{formatDateInTimeZone(buildCoveragePeriod(coverage, 'start'), 'MM/dd/yyyy', 'UTC')}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Period End</dt>
                </Col>
                <Col sm={9}>
                  <dd>{formatDateInTimeZone(buildCoveragePeriod(coverage, 'end'), 'MM/dd/yyyy', 'UTC')}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Payor</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoveragePayer(coverage)}</dd>
                </Col>
              </Row>
              { 
                coverage.class && coverage.class.map((c: any) => (
                  <Row key={c.value}>
                    <Col sm={3}>
                      <dt>{capitalizeFirstLetter(c.type.coding[0].code)}</dt>
                    </Col>
                    <Col sm={9}>
                      {c.value} {c.name}
                      <dd></dd>
                    </Col>
                  </Row>
                ))
              }
              <Row>
                <Col sm={3}>
                  <dt>Resource URL</dt>
                </Col>
                <Col sm={9}>
                  <dd>{coverageData.link[0].url}</dd>
                </Col>
              </Row>
            </dl>
          ))
        ) : 
          <Row>
            <Col className='text-center'>
                No Coverage found for this member.
            </Col>
          </Row>
      }
    </>
  )
}

export default Coverage