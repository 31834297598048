import { useMutation, useQueryClient } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'
import { getToken } from '../../utils/helpers'

export default function useSaveAccount (accountId: string): any {
  const token = getToken()
  const queryClient = useQueryClient()
  return useMutation(
    (values: any) => request.put(`${getApiServer()}/account/${accountId}`).set('Authorization', 'Bearer ' + token ).set('Accept', 'application/json').send(values).then((res) => res.body),
    {
      onMutate: (values) => {
        const oldAccount: any = queryClient.getQueryData(['account', accountId])
        queryClient.setQueryData(['app', accountId], {
          ...oldAccount,
          ...values
        })
      },
      onSuccess: async (values) => {
        await queryClient.refetchQueries(['account', accountId])
      }
    }
  )
}