import React, { FC, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ForgotLoginModal from '../../components/Modals/ForgotLoginModal'
import LoginForm from './LoginForm'
import ChangePasswordForm from './ChangePasswordForm'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { LoginSchema, ChangePasswordSchema } from '../../utils/validation'
import useQuery from '../../hooks/location/useQuery'
import useLoginUser from '../../hooks/authentication/useLoginUser'
import useUser from '../../hooks/authentication/useUser'
import useForgotLogin from '../../hooks/authentication/useForgotLogin'
import useChangePassword from '../../hooks/authentication/useChangePassword'
import './index.css'

const Login: FC = () => {
  const [loginAlert, setLoginState] = useState({
    show: false,
    variant: 'danger',
    message: ''
  })

  const handleCloseLoginAlert = () => {
    setLoginState((ps) => ({
      ...ps,
      show: false
    }))
  }

  const [changePassAlert, setChangePassAlert] = useState({
    show: false,
    variant: 'danger',
    message: ''
  })

  const handleCloseChangePassAlert = () => {
    setChangePassAlert((ps) => ({
      ...ps,
      show: false
    }))
  }

  const [showForgotLogin, setShowForgotLogin] = useState(false)
  const [modalType, setModalType] = useState('')
  const forgotLogin = useForgotLogin()
  const handleOpenForgotLogin = (type: string) => {
    setShowForgotLogin(true)
    setModalType(type)
  }
  const handleHideForgotLogin = () => {
    setShowForgotLogin(false)
  }

  const history = useHistory()
  const query = useQuery()
  const screen = query.get('screen')

  const loginUser = useLoginUser()
  const { data, refetch } = useUser()

  const loginFormHandler = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      loginUser.mutate(values)
    },
    enableReinitialize: true
  })

  useEffect(() => {
    if (loginUser.isSuccess) {
      refetch()
    }

    if (loginUser.isError) {
      setLoginState({
        show: true,
        message: loginUser.error?.response.text,
        variant: 'danger'
      })
    }
  }, [loginUser.isSuccess, loginUser.isError])

  const changePassword = useChangePassword()
  const changePasswordFormHandler = useFormik({
    initialValues: {
      password: '',
      passwordAgain: ''
    },
    onSubmit: (values) => {
      changePassword.mutate({
        ...values,
        code: query.get('code')
      })
    },
    validationSchema: ChangePasswordSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    if (changePassword.isSuccess) {
      setLoginState({
        show: true,
        variant: 'success',
        message: 'Successfully changed your password.'
      })
      history.push('/login')
    }
    if (changePassword.isError) {
      setLoginState({
        show: true,
        variant: 'danger',
        message: changePassword.error?.response.text
      })
    }
  }, [changePassword.isSuccess, changePassword.isError]) 

  useEffect(() => {
    if (data) {
      if (data.role.find((a: string | string[]) =>a.includes('admin'))) {
        history.push('/admin')
      } else {
        history.push('/')
      }
    }
  }, [data])

  return (
    <Container
      style={{
        paddingTop: 10,
        maxWidth: '960px',
        margin: '1em auto'
      }}
    >
      <Row>
        <Col md={6} className='left'>
          <h3>
            <Link to='/register/requestor'>Member Registration</Link>
          </h3>
          <div>
            {
              screen ? (
                <ChangePasswordForm
                  formHandler={changePasswordFormHandler}
                  alertState={changePassAlert}
                  closeAlert={handleCloseChangePassAlert}
                />
              ) : (
                <LoginForm
                  formHandler={loginFormHandler}
                  alertState={loginAlert}
                  closeAlert={handleCloseLoginAlert}
                  openForgotModal={handleOpenForgotLogin}
                  isLoading={loginUser.isLoading}
                />
              )
            }
          </div>
        </Col>
        <Col md={6} className='right'>
          <section className='helpContainer'>
            <p>App Connect is a tool that allows you to connect your Chorus Community Health Plans (CCHP) or Care4Kids health information to third party health history apps.</p>
            <p>
              If you are a current or previous member of CCHP or Care4Kids with a health record created after December 31, 2015, you are eligible for App Connect. <a href="/register">Register now.</a>
            </p>
            <h3><a href="/help">Learn More about App Connect</a></h3>
          </section>
        </Col>
      </Row>
      <ForgotLoginModal 
        show={showForgotLogin}
        onHide={handleHideForgotLogin}
        modalType={modalType}
        forgotLogin={forgotLogin}
      />
    </Container>
  )
}

export default Login
