import React, { FC } from 'react'
import { Form, Alert, Row, Col, Button, Spinner } from 'react-bootstrap'

interface Props {
    formHandler: any;
    alertState: { show: boolean; variant: string; message: string; };
    closeAlert: () => void;
    openForgotModal: (type: string) => void;
    isLoading: boolean;
}

const LoginForm: FC<Props> = ({ formHandler, alertState, closeAlert, openForgotModal, isLoading }: Props) => {
  return (
    <>
      <h3 className='headerText'>Log In</h3>
      <Form noValidate onSubmit={formHandler.handleSubmit} id='login'>
        {
          alertState.show && (
            <Alert variant={alertState.variant} dismissible onClose={closeAlert}>
              {alertState.message}
            </Alert>
          )
        }
        <Form.Group className='custom'>
          <Form.Label>Username:</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Username'
            name='username'
            value={formHandler.values.username}
            onChange={formHandler.handleChange}
            onBlur={formHandler.handleBlur}
            isInvalid={!!formHandler.errors.username && formHandler.touched.username}
            autoComplete='username'
            id='username'
          />
          <Row>
            <Col>
              {
                !!formHandler.errors.username && formHandler.touched.username && (
                  <Form.Text className='form-error'>
                    {formHandler.errors.username}
                  </Form.Text>
                )
              }
            </Col>
            <Col className='text-right'>
              <p className='text-right'><small><span style={{ color: '#000', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => openForgotModal('Username')}>Forgot Username?</span></small></p>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className='custom'>
          <Form.Label>Password:</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Password'
            name='password'
            autoComplete='current-password'
            value={formHandler.values.password}
            onChange={formHandler.handleChange}
            onBlur={formHandler.handleBlur}
            isInvalid={!!formHandler.errors.password && formHandler.touched.password}
            id='pwd'
          />
          <Row>
            <Col>
              {
                !!formHandler.errors.password && formHandler.touched.password && (
                  <Form.Text className='form-error'>
                    {formHandler.errors.password}
                  </Form.Text>
                )
              }
            </Col>
            <Col className='text-right'>
              <p>
                <small>
                  <span style={{ color: '#000', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => openForgotModal('Password')}>Forgot Password?</span>
                </small>
              </p>
            </Col>
          </Row>
        </Form.Group>
        <div>
          { !isLoading ?
            <Button type='submit' className='custom'>Log In</Button>
            : 
            <Button type='submit' className='custom'>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </Button>
          }
        </div>
      </Form>
    </>
  )
}

export default LoginForm