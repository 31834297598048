import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'
import { getToken } from '../../utils/helpers'

export default function useStatuses (): any {
  const token = getToken()
  return useQuery('admin-status', async () => {
    return await request.get(`${getApiServer()}/admin`).set('Authorization', 'Bearer ' + token ).set('Accept', 'application/json').then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 3600000,
    retry: 0
  })
}