import React, { FC, useState, useEffect } from 'react'
import { Alert, Accordion, Breadcrumb, Button, Col, Container, Row } from 'react-bootstrap'
import ChangePassword from '../../../components/Member/ChangePassword'
import useChangePassword from '../../../hooks/authentication/useChangePassword'
import useUser from '../../../hooks/authentication/useUser'
import isAuthenticated from '../../../routes/PrivateRoute'
import { calculateAge } from '../../../utils/helpers'

const initialPasswordValues = {
  password: '',
  passwordAgain: ''
}

const initialAlertState = {
  show: false,
  variant: 'success',
  message: ''
}

const MemberAccount: FC = () => {

  const { data: user } = useUser()

  let role = ''
  if (user) {
    if (user.role.find((a: string[]) => a.includes('admin'))) {
      role = 'admin'
    }

    if (user.role.find((a: string[]) => a.includes('member'))) {
      role = 'member'
    }
  }

  const userChangePassword = useChangePassword()

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>My Account</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col sm={6}>
          <h2>My Account</h2>
        </Col>
        <Col sm={12}>
          <section className='dataContainer'>
            <dl>
              {
                role === 'member' && (
                  <>
                    <Row>
                      <Col sm={3}>
                        <dt>Account Owner Name</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {
                            user.accountOwner
                          }
                        </dd>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <dt>Account Owner Type</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {user.accountOwnerType}
                        </dd>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <dt>Email</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {user?.email}
                        </dd>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <dt>Member Name</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {user.name}
                        </dd>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <dt>Member ID</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {user.memberId}
                        </dd>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <dt>Member Age</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {calculateAge(user.dateOfBirth)}
                        </dd>
                      </Col>
                    </Row>
                  </>
                )
              }
              <Row>
                <Col sm={3}>
                  <dt>Username</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {user.preferred_username}
                  </dd>
                </Col>
              </Row>
              <Accordion as={Row}>
                <Col sm={3}>
                  <dt>Change Password</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    <Accordion.Toggle as={Button} eventKey='0' className='memberSubmitBtn'>
                      Change Password
                    </Accordion.Toggle>
                  </dd>
                </Col>
                <Col sm={12}>
                  <Accordion.Collapse eventKey="0">
                    <Container>
                      <ChangePassword
                        userId={user.sub}
                        userRole={role}
                        changePassword={userChangePassword} 
                        initialValues={initialPasswordValues}
                      />
                    </Container>
                  </Accordion.Collapse>
                </Col>
              </Accordion>
              <Row>
                <Col sm={3}>
                  <dt>Account Status</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {user.enabled ? 'Active' : 'Inactive'}
                  </dd>
                </Col>
              </Row>
            </dl>
          </section>
        </Col>
      </Row>
    </Container>
  )
}

export default isAuthenticated(MemberAccount)