import React, { FC, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { buildEOBClaimId, buildEOBBillableStart, buildEOBInsurer, buildEOBOutcome, buildEOBPayee, buildEOBDiagnosis, buildEOBStatus, buildEobType, buildEOBUse, buildEOBBillableEnd, buildEOBCreatedDate, buildEOBProvider } from '../../../../utils/fhirUtils/eob'
import { buildPatientId } from '../../../../utils/fhirUtils/patient'
import EobInstitutionalItem from '../EobInstitutionalItem'
import EobPharmItem from '../EobPharmItem'
import EobProfessionalItem from '../EobProfessionalItem'
import useFhirResource from '../../../../hooks/admin/useFhirResource'

interface Props {
    eobDetailId: string;
    eobData: any;
    patientData: any;
}

const EobDetail: FC<Props> = ({ eobDetailId, patientData }: Props) => {
  const { data: eobData, refetch: getPatientEob, isStale: eobsIsStale, isFetching: fetchingEobs } = useFhirResource(patientData?.id, 'ExplanationOfBenefit', 1, '', '', eobDetailId)
  useEffect( () => {
    getPatientEob()
  },[])
  const patientEob = eobData && eobData?.entry ? eobData && eobData.entry[0] : ''
  
  return (
    <>
      {
        fetchingEobs ? (
          <div className='d-flex dataContainer'>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
        Loading EOB Data...
          </div>
        ) : patientData && Object.keys(patientData).length > 0 ? ( 
          <dl className='dataContainer'>
            <Row>
              <Col sm={3}>
                <dt>Member ID</dt>
              </Col>
              <Col sm={9}>
                <dd>{patientData && buildPatientId(patientData)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Claim ID</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientEob && buildEOBClaimId(patientEob.resource)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Type</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientEob && buildEobType(patientEob.resource)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Status</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientEob && buildEOBStatus(patientEob.resource)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Use</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientEob && buildEOBUse(patientEob.resource)}</dd>
              </Col>

              <>{ patientEob &&
          patientEob.resource?.billablePeriod && 
        <><Col sm={3} className='mt-3'>
          <dt>Billable Period Start</dt>
        </Col><Col sm={9} className='mt-3'>
          <dd>{buildEOBBillableStart(patientEob.resource)}</dd>
        </Col><Col sm={3} className='mt-3'>
          <dt>Billable Period End</dt>
        </Col><Col sm={9} className='mt-3'>
          <dd>{buildEOBBillableEnd(patientEob.resource)}</dd>
        </Col></>
              }</>

              <Col sm={3} className='mt-3'>
                <dt>Created Date</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientEob && buildEOBCreatedDate(patientEob?.resource)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Insurer</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientEob && buildEOBInsurer(patientEob.resource)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Insurance</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>
                  <div>
                    {
                      patientEob && patientEob.resource?.insurance && patientEob.resource.insurance[0].coverage && (
                        <>
                          {patientEob.resource.insurance[0].coverage.reference}
                        </>
                      )
                    }
                  </div>
                </dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Provider</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientEob && buildEOBProvider(patientEob.resource)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Payee</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientEob && buildEOBPayee(patientEob.resource)}</dd>
              </Col>
              { patientEob && patientEob.resource?.outcome && 
          <><Col sm={3} className='mt-3'>
            <dt>Outcome</dt>
          </Col><Col sm={9} className='mt-3'>
            <dd>{buildEOBOutcome(patientEob.resource)}</dd>
          </Col></>
              }
              <Col sm={3} className='mt-3'>
                <dt>Care Team</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                {patientEob && patientEob.resource?.careTeam ? patientEob.resource.careTeam.map((team: any) => (
                  <div key={team.provider.reference}>
                    {team.provider.reference}
                    {
                      team.qualification && (
                        <div>
                    Qualification: {team.qualification.text}
                        </div>
                      )
                    }
                  </div>
                )) : ''}
              </Col>
              { patientEob && patientEob.resource?.diagnosis && 
        <><Col sm={3} className='mt-3'>
          <dt>Diagnosis</dt>
        </Col><Col sm={9} className='mt-3'>
          {buildEOBDiagnosis(patientEob.resource.diagnosis)}
        </Col></>
              }
              <Col sm={3} className='mt-3'>
                <dt>Resource Link</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientEob && patientEob?.fullUrl}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Items</dt>
              </Col>
              <Col sm={9}>
                { patientEob &&
            buildEobType(patientEob.resource) === 'Professional' && 
                <EobProfessionalItem 
                  patientEob={patientEob} 
                />
                }
                { patientEob &&
            buildEobType(patientEob.resource) === 'Pharmacy' && 
                <EobPharmItem 
                  patientEob={patientEob} 
                />
                }
                { patientEob &&
            buildEobType(patientEob.resource) === 'Institutional' && 
                <EobInstitutionalItem 
                  patientEob={patientEob}
                />
                }
                { patientEob &&
            buildEobType(patientEob.resource) === 'Medical' && 
                <EobProfessionalItem 
                  patientEob={patientEob}
                />
                }
              </Col>
            </Row>
          </dl>
        ) : 
          <tr>
            <td colSpan={6} rowSpan={15} className='text-center'>
              No EOB Data found for this claim.
            </td>
          </tr>
      }
    </>
  )
}

export default EobDetail