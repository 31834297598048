import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatObservationsForTable } from '../../../../utils/fhirUtils/observation'
import { buildPatientId } from '../../../../utils/fhirUtils/patient'
import { formatDateInTimeZone } from '../../../../utils/helpers'

interface Props {
    patientData: any;
    observationData: any;
    isFetching: boolean;
}

const ObservationList: FC<Props> = ({ patientData, observationData, isFetching }: Props) => {
  const patientObservations = formatObservationsForTable(observationData, patientData)
  return (
    <Table size='sm' hover>
      <thead>
        <tr>
          <th scope='col'>Member ID</th>
          <th scope="col">Observation ID</th>
          <th scope="col">Effective Date</th>
          <th scope="col">View Detail</th>
        </tr>
      </thead>
      {
        isFetching ? (
          <tr>
            <td colSpan={6} className='d-flex'>
              <Spinner
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
              />
                    Loading Member Observations...
            </td>
          </tr>
        ) : patientObservations.length === 0 ? (
          <tr>
            <td colSpan={6} rowSpan={15} className='text-center'>
                  No Observations found for this member.
            </td>
          </tr>
        ) : (
          <>
            {
              patientObservations.map((observation: any) => (
                <tr key={observation.observationId}>
                  <th scope='row'>{observation.memberId}</th>
                  <td scope='col'>{observation.observationId}</td>
                  <td scope='col'>{formatDateInTimeZone(observation.effectiveDate, 'MM/dd/yyyy', 'UTC')}</td>
                  <td scope='col'><Link to={`/patient/${buildPatientId(patientData)}/Observations/${observation.observationId}`}>View Detail</Link></td>
                </tr>
              ))
            }
          </>
        )
      }
    </Table>
  )
}

export default ObservationList