import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatDateWithFormat } from '../../../../utils/helpers'

interface Props {
    detailId: string | undefined;
    observationData: any;
    patientData: any;
}

const ObservationDetail: FC<Props> = ({ detailId, observationData, patientData }: Props) => {
  if (!observationData) return <div />
  const observation = observationData.entry.find((observation: any) => observation.resource.id === detailId).resource
  return ( 
    <dl className='dataContainer'> 
      <Row>
        <Col sm={3}>
          <dt>Member ID</dt>
        </Col>
        <Col sm={9}>
          <dd>{patientData.id}</dd>
        </Col>
        <Col sm={3}>
          <dt>Observation ID</dt>
        </Col>
        <Col sm={9}>
          <dd>{observation.id}</dd>
        </Col>
        <Col sm={3}>
          <dt>Effective Date</dt>
        </Col>
        <Col sm={9}>
          <dd>{formatDateWithFormat(observation.effectiveDateTime, 'MM/dd/yyyy')}</dd>
        </Col>
        <Col sm={3}>
          <dt>Status</dt>
        </Col>
        <Col sm={9}>
          <dd>{observation.status}</dd>
        </Col>
        <Col sm={3}>
          <dt>Code</dt>
        </Col>
        <Col sm={9}>
          <dd>{observation.code.coding[0].code} ({observation.code.coding[0].display})</dd>
        </Col>
        <Col sm={3}>
          <dt>Components</dt>
        </Col>
        <Col sm={9}>
          <dd>
            {
              observation.component.map((c: any, index: number) => (
                <p className="mb-2" key={`${index}${c.valueString}`}>
                  {c.code?.text} - {c?.valueString}
                </p>
              ))
            }
          </dd>
        </Col>
        <Col sm={3}>
          <dt>Resource URL</dt>
        </Col>
        <Col sm={9}>
          <dd> https://18.233.54.217:9090/fhir/Observation/{observation.id}</dd>
        </Col>
      </Row>
    </dl>
  )
}

export default ObservationDetail