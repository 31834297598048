import request from 'superagent'
import { useMutation } from 'react-query'
import { getApiServer } from '../../utils/domain'
import { getToken } from '../../utils/helpers'

interface HTTPError extends Error {
  status?: number;
  response?: any;
}

export default function useLogoutUser() {
  const token = getToken()
  return useMutation(
    (values: any) => request.post(`${getApiServer()}/logout`).set('Authorization', 'Bearer ' + token ).set('Accept', 'application/json').send(values).then((res) => res.body), {
      onError: (error: HTTPError) => {
        return error.response.text
      },
      onSuccess: () => {
        localStorage.removeItem('cchp-token')
      }
    }
  )
}