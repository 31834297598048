import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import MaskedInput from 'react-maskedinput'
import { useFormik } from 'formik'
import { MemberSchema } from '../../../utils/validation'
import { mainColors } from '../../../assets/colors'
import './index.css'
import { Link } from 'react-router-dom'
import { isBefore, sub } from 'date-fns'

interface Props {
    initialValues: any;
    memberType: string;
    validateResults: any;
    isLoading: boolean;
    isError: boolean;
    error: string;
    onSubmit: (values: any) => void;
}

const Member: FC<Props> = ({ initialValues, validateResults, isLoading, isError, error, memberType, onSubmit }: Props) => {
  const [showError, setShowError] = useState(false)
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      memberType
    },
    validationSchema: MemberSchema,
    onSubmit: (values) => {
      onSubmit({
        memberId: values.memberId,
        dob: values.dob,
        ssn: values.ssn
      })
    },
    enableReinitialize: true
  })

  useEffect(() => {
    if (validateResults) {
      if (validateResults.length !== 1 || validateResults[0].status === 'ACTIVE') setShowError(true)
    }

    if (isError) {
      setShowError(true)
    }
  }, [isError, validateResults])

  return (
    <Form noValidate onSubmit={formik.handleSubmit} id='member'>
      <h4 style={{ color: mainColors.darkCyan }}>Member</h4>
      {
        showError && (
          <Alert variant='danger' dismissible onClose={() => setShowError(false)}>
            {
              error ? (
                <div dangerouslySetInnerHTML={{__html: error }} />
              ) : validateResults?.length !== 1
                ? 'We were not able to verify this member in our system based on the information provided. If you need to locate your Member ID, please call one of the support numbers'
                : (
                  <>
                    Account already exists. You can try resetting your password or can <Link to='/contact'>contact us</Link> with any questions or concerns.
                  </>
                )
            }
          </Alert>
        )
      }
      <Form.Group>
        <Form.Label>Member ID:</Form.Label>
        <Form.Control 
          type='text' 
          name='memberId' 
          value={formik.values.memberId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.memberId && !!formik.touched.memberId}
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.memberId}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mt-3'>
        <Form.Label>Date of Birth:</Form.Label>
        <Form.Control 
          as={MaskedInput}
          mask='11/11/1111'
          placeholder='MM/DD/YYYY'
          name='dob' 
          value={formik.values.dob}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.dob && !!formik.touched.dob}
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.dob} {' '} 
          {
            memberType === 'parent' && isBefore(new Date(formik.values.dob), sub(new Date(), { years: 18})) && (
              <>
                If an exeption is needed, please <Link to='/contact'>contact us</Link>.
              </>
            )
          }
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mt-3'>
        <Form.Label>Last 4 Digits of SS#:</Form.Label>
        <Form.Control 
          type='text' 
          name='ssn'
          value={formik.values.ssn}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.ssn && !!formik.touched.ssn}
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.ssn}
        </Form.Control.Feedback>
      </Form.Group>
      <Link to='/register/requestor' className='btn btn-light mr-3'>
        &lt;&lt; Back
      </Link>
      <Button className='memberSubmitBtn' type='submit'>
        {
          isLoading ? (
            <Spinner 
              as='span'
              animation='border'
              role='status'
              size='sm'
              aria-hidden='true'
            />
          ) : 'Next >>'
        }
        
      </Button>
    </Form>
  )
}

export default Member