import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'
import { getToken } from '../../utils/helpers'

export default function useFhirResource (memberId: string, resource: string, count = 1, getPages = '', getPagesOffset = '', resourceId = ''): any {
  const token = getToken()
  return useQuery([memberId, resource, count, getPages, getPagesOffset, resourceId], async () => {
    const queryParams: any = {
      count,
      resourceId
    }

    if (getPages) {
      queryParams.getPages = getPages
    }

    if (getPagesOffset) {
      queryParams.getPagesOffset = getPagesOffset
    }

    return await request.get(`${getApiServer()}/fhir/${resource}/${memberId}`).query({ ...queryParams }).set('Authorization', 'Bearer ' + token ).set('Accept', 'application/json').then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 3600000,
    retry: 0,
    keepPreviousData: true,
    enabled: false
  })
}