export const formatImmunizationDataForTable = (immunizations: any, memberId: string) => {
  if (!immunizations || !immunizations.entry) return []
  const patientImmunizations = immunizations.entry.map((immunization: any) => immunization.resource)
  return patientImmunizations.map((patientImmunization: any) => {
    return ({
      memberId,
      immunizationId: patientImmunization.id,
      occurrenceDate: patientImmunization.occurrenceDateTime,
      vaccine: patientImmunization.vaccineCode.text
    })
  })
}

