import React, { FC } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Header from '../components/Header'
import Login from '../containers/Login'
import MemberHome from '../containers/MemberHome'
import MemberAccount from '../containers/MemberHome/Account'
import Apps from '../containers/Apps'
import App from '../containers/App'
import Admin from '../containers/Admin'
import Members from '../containers/Admin/Members'
import AdminMember from '../containers/Admin/Member'
import Users from '../containers/Admin/Users'
import Developer from '../containers/Developer'
import AppRegister from '../containers/Developer/AppRegister'
import AppEdit from '../containers/Developer/AppEdit'
import Register from '../containers/Register'
import Help from '../components/Help'
import Footer from '../components/Footer'
import Contact from '../containers/Contact'
import Account from '../components/Admin/Account'
import AccessDenied from '../components/AccessDenied'
import PatientApi from '../components/PatientApi'
import ProviderApi from '../components/ProviderApi'
import Providers from '../containers/Admin/Providers'
import Provider from '../containers/Admin/Provider'

const Routes: FC = () => {

  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route
            exact
            path='/'
            render={(props) => <MemberHome {...props}  />}
          />
          <Route
            exact
            path='/my-account'
            render={props => <MemberAccount {...props} />}
          />
          <Route
            exact
            path='/apps'
            component={Apps}
          />
          <Route
            exact
            path='/app/:appId'
            component={App}
          />
          <Route
            exact
            path='/admin'
            render={props => <Admin {...props} />}
          />
          <Route
            exact
            path='/members'
            render={props => <Members {...props} />}
          />
          <Route 
            exact
            path='/providers'
            render={props => <Providers {...props} />}
          />
          <Route
            exact
            path='/provider/:providerId'
            component={Provider}
          />
          <Route
            exact
            path='/patient/:memberId/:page?/:detailId?'
            component={AdminMember}
          />
          <Route
            exact
            path='/accounts'
            component={Users}
          />
          <Route
            exact
            path='/account/:accountId'
            component={Account}
          />
          <Route
            exact
            path='/developers'
            component={Developer}
          />
          <Route
            exact 
            path='/developers/patientapi'
            component={PatientApi}
          />
          <Route
            exact 
            path='/developers/providerapi'
            component={ProviderApi}
          />
          <Route
            exact
            path="/app-register"
            component={AppRegister}
          />
          <Route
            exact
            path="/app-edit/:appId"
            component={AppEdit}
          />
          <Route
            exact
            path='/help'
            component={Help}
          />
          <Route
            exact
            path='/contact'
            component={Contact}
          />
          <Route
            exact
            path='/register/:step?'
            component={Register}
          />
          <Route
            exact
            path='/login'
            component={Login}
          />
          <Route
            exact
            path='/access-denied'
            component={AccessDenied}
          />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default Routes