import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Form, Modal, Spinner } from 'react-bootstrap'
import { useFormik } from 'formik'
import { mainColors } from '../../../assets/colors'
import { ForgotSchema } from '../../../utils/validation'

interface Props {
  show: boolean;
  onHide: () => void;
  modalType: string;
  forgotLogin: any;
}

const ForgotLoginModal: FC<Props> = ({ show, onHide, modalType, forgotLogin }: Props) => {
  const [alertState, setAlertState] = useState({
    show: false,
    variant: 'success',
    message: ''
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      memberId: ''
    },
    enableReinitialize: true,
    validationSchema: ForgotSchema,
    onSubmit: (values) => {
      forgotLogin.mutate({
        ...values,
        type: modalType
      })
    }
  })

  useEffect(() => {
    if (forgotLogin.isSuccess) {
      setAlertState({
        show: true,
        variant: 'success',
        message: modalType === 'Username' ? 'Please check your email for username' : 'Please check your email for next steps.'
      })
    }

    if (forgotLogin.isError) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: forgotLogin.error.response.text
      })
    }
  }, [forgotLogin.isSuccess, forgotLogin.isError])

  const handleCloseModal = () => {
    setAlertState({
      show: false,
      variant: 'success',
      message: ''
    })

    onHide()
  }
  return (
    <Modal show={show} onHide={handleCloseModal} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: mainColors.darkCyan }}>Forgot {modalType}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          {
            alertState.show && (
              <Alert variant={alertState.variant} dismissible onClose={() => setAlertState((ps => ({ ...ps, show: false })))}>
                {alertState.message}
              </Alert>
            )
          }
          <Card.Text>Please enter your Member ID and the email address associated with your account.</Card.Text>
          <Form.Group>
            <Form.Label>Member ID</Form.Label>
            <Form.Control
              type='text'
              name='memberId'
              value={formik.values.memberId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.memberId && !!formik.touched.memberId}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.memberId}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='text'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.email && !!formik.touched.email}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <div className='float-right mt-3'>
            <Button className='mr-2 modalBtn' variant='light' onClick={handleCloseModal}>
              Close
            </Button>
            <Button 
              className='modalBtn'
              type='submit'
              style={{ backgroundColor: mainColors.darkCyan }}
            >
              {
                forgotLogin.isLoading ? (
                  <Spinner 
                    as='span'
                    animation='border'
                    role='status'
                    size='sm'
                    aria-hidden='true'
                  />
                ) : 'Submit'
              }
            </Button>      
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default ForgotLoginModal
