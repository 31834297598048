import React, { FC, useState } from 'react'
import { Alert, Breadcrumb, Button, Container, Form, Spinner } from 'react-bootstrap'
import { useFormik } from 'formik'
import { ContactSchema } from '../../utils/validation'
import useSendQuestion from '../../hooks/help/useSendQuestion'
import { useEffect } from 'react'

const Contact: FC = () => {
  const [alertState, setAlertState] = useState({ show: false, variant: 'info', message: '' })
  const sendQuestion = useSendQuestion()

  useEffect(() => {
    if (sendQuestion.isSuccess) {
      setAlertState({
        show: true,
        variant: 'success',
        message: 'Thank you for contacting App Connect Support. We will get back to as soon as we can, but usually within one business day.'
      })
    }
    if (sendQuestion.isError) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: 'Unable to send message: '
      })
    }
  }, [sendQuestion.isSuccess, sendQuestion.isError])

  const contactForm = useFormik({
    initialValues: {
      name: '',
      email: '',
      memberId: '',
      message: ''
    },
    onSubmit: (values) => {
      sendQuestion.mutate(values)
    },
    validationSchema: ContactSchema,
    enableReinitialize: true
  })

  return (
    <Container
      style={{
        margin: '1em auto',
        maxWidth: '820px'
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
        <Breadcrumb.Item href='/help'>Help</Breadcrumb.Item>
        <Breadcrumb.Item active aria-current='page'>Contact App Connect Support</Breadcrumb.Item>
      </Breadcrumb>
      
      <h2>Contact App Connect Support</h2>
      <p>
      Please use this form to send an email to the App Connect Support Team at CCHP. We will get back to you as soon as we can, but usually within one business day.
      </p>
      <Alert show={alertState.show} variant={alertState.variant} dismissible onClose={() => setAlertState((ps) => ({ ...ps, show: false }))}>
        {alertState.message}
        {
          sendQuestion.isError && (<div dangerouslySetInnerHTML={{ __html: sendQuestion.error?.response.text }} />)
        }
      </Alert>
      <Form noValidate onSubmit={contactForm.handleSubmit}>
        <Form.Group>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            name='name'
            type='text'
            value={contactForm.values.name}
            onChange={contactForm.handleChange}
            onBlur={contactForm.handleBlur}
            isInvalid={!!contactForm.errors.name && contactForm.touched.name}
          />
          <Form.Control.Feedback type='invalid'>
            {contactForm.errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Email:</Form.Label>
          <Form.Control
            name='email'
            type='text'
            value={contactForm.values.email}
            onChange={contactForm.handleChange}
            onBlur={contactForm.handleBlur}
            isInvalid={!!contactForm.errors.email && contactForm.touched.email}
          />
          <Form.Control.Feedback type='invalid'>
            {contactForm.errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Member ID:</Form.Label>
          <Form.Control
            name='memberId'
            type='text'
            value={contactForm.values.memberId}
            onChange={contactForm.handleChange}
            onBlur={contactForm.handleBlur}
            isInvalid={!!contactForm.errors.memberId && contactForm.touched.memberId}
          />
          <Form.Control.Feedback type='invalid'>
            {contactForm.errors.memberId}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>How Can We Help?:</Form.Label>
          <Form.Control
            name='message'
            as='textarea'
            rows={6}
            value={contactForm.values.message}
            onChange={contactForm.handleChange}
            onBlur={contactForm.handleBlur}
            isInvalid={!!contactForm.errors.message && contactForm.touched.message}
          />
          <Form.Control.Feedback type='invalid'>
            {contactForm.errors.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Button type='submit' className='custom' disabled={sendQuestion.isLoading}>
          {
            sendQuestion.isLoading ? (
              <Spinner 
                as='span'
                animation='border'
                role='status'
                size='sm'
                aria-hidden='true'
              />
            ) : 'Send'
          }
        </Button>
      </Form>
    </Container>
  )
}

export default Contact