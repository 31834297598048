import React, { FC, useEffect } from 'react'
import { Col, Container, ListGroup, Jumbotron, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useApps from '../../hooks/app/useApps'
import useUser from '../../hooks/authentication/useUser'
import isAuthenticated from '../../routes/PrivateRoute'

import './index.css'

const MemberHome: FC = () => {
  const { data: user } = useUser()
  const { data: apps, refetch: refetchApps } = useApps('APPROVED')

  useEffect(() => {
    refetchApps()
  }, [])


  return (
    <Container
      id='MemberHome'
      style={{
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Row>
        <Col md={3} className='p-3 border'>
          <h3>Member Menu</h3>
          <ListGroup as='ul' variant='flush' className='m-0 p-0'>
            <ListGroup.Item as='li' className='mb-1 p-1'>
              {apps?.length > 0 ?
                <Link to='/apps?status=APPROVED'>Available Apps</Link>
                :
                <>
                  <Row>
                    <Col sm={7}>
                      <h4 className='noAppsHeader'>Available Apps</h4>
                    </Col>
                    <Col sm={5}>
                      <p className='text-danger'>Pending</p>
                    </Col>
                  </Row>

                  <p className='memberParagraph'>
                    We are still evaluating apps and hope to have one or more available in the near future. The availability of apps that connect to FHIR servers and securely store your information is a growing and developing marketplace, but at this time they are still limited in quality and availability.
                  </p>
                  <p className='memberParagraph'>
                    Please <Link to='/help' style={{ fontWeight: 'normal', fontSize: '16px' }}>contact us</Link> with any questions.
                  </p>
                </>
              }

            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={9} className='p-3 border'>
          <Jumbotron id='jumbo'>
            <h1 className='h1'>Hello {user.accountOwner}</h1>
            <h3 className='text-muted mt-2'>Member Name: {user.name}</h3>
            <h3 className='text-muted mt-2'>Member ID: {user.memberId}</h3>
            {apps?.length > 0 &&
              <p className='memberParagraph'>
                Please check out the list of <Link to='/apps?status=APPROVED' style={{ color: '#047fbb' }}> Available Apps </Link> that you can use to connect to your health information at CCHP, Together with CCHP or Care4Kids.
              </p>
            }
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  )
}

export default isAuthenticated(MemberHome)
