import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useApps (filter: string): any {
  return useQuery(['apps', filter], async () => {
    return await request.get(`${getApiServer()}/apps`).query({ query: filter }).set('Accept', 'application/json').then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 3600000,
    retry: 0,
    enabled: false
  })
}