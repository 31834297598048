import React, { FC, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { determineEnvironment, determineEnvironmentProvider } from '../../utils/helpers'

import './index.css'

const Developer: FC = () => {

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: 840
      }}
    >
      <h2>CCHP API Information</h2>
    
      <p>These are the FHIR endpoints and server information for CCHP and Care4Kids. This interoperability installation conforms to <a href="http://hl7.org/fhir/r4/conformance-module.html" target="_blank" rel="noreferrer">FHIR v4.0.1</a>, <a href="https://oauth.net/2" target="_blank" rel="noreferrer">OAuth 2.0</a> and <a href="https://docs.smarthealthit.org/" target="_blank" rel="noreferrer">SMART on FHIR</a> standards and specifications.</p>
    

      <h3>Patient Access API</h3>	
    
      <p><strong>Capability Statement:</strong> https://cchp.healthlx.com:9090/fhir/metadata [<Link to="/developers/patientapi">JSON</Link>]</p>

      <h3>Provider Directory API</h3>	
    
      <p><strong>Capability Statement:</strong> https://cchp.healthlx.com:9091/fhir/metadata [<Link to="/developers/providerapi">JSON</Link>]</p>

      <h3><Link to="/app-register">App Developer Registration</Link></h3>
    </Container>
    
  )
}

export default Developer