import React, { FC } from 'react'
import { Button, Col, Container, ListGroup, Row, Form } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useFormik} from 'formik'
import useStatuses from '../../hooks/admin/useStatuses'
import isAuthenticated from '../../routes/PrivateRoute'
import { capitalizeFirstLetter, numberWithCommas, determineEnvironment, determineEnvironmentProvider } from '../../utils/helpers'
import { memberIdSchema } from '../../utils/validation'

import './index.css'

const Admin: FC = () => {
  const { origin } = window.location
  const history = useHistory()
  const { data } = useStatuses()

  const memberDataForm = useFormik({
    initialValues: {
      memberId: ''
    },
    validationSchema: memberIdSchema,
    onSubmit: (values) => {
      history.push(`/patient/${values.memberId}`)
    },
    enableReinitialize: true
  })

  const providerDataForm = useFormik({
    initialValues: {
      providerId: ''
    },
    onSubmit: (values) => {
      history.push('/providers/')
    },
    enableReinitialize: true
  })

  return (
    <Container
      id='AdminHome'
      style={{
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Row>
        <Col md={3} className='p-3 border'>
          <h3>Admin Menu</h3>
          <ListGroup as='ul' variant='flush' className='m-0 p-0'>
            <ListGroup.Item as='li' className='mb-1 p-1'>
              <Link to='/members'>Find Members</Link> <span className='d-md-block text-secondary'>({data && numberWithCommas(data.memberCount)} in Validation Table)</span>
            </ListGroup.Item>
            <ListGroup.Item as='li' className='mb-1 p-1'>
              <Link to='/accounts?accountOwnerType=all&accountStatus=active'>Member Accounts</Link> <span className='d-md-block text-secondary'>({data && numberWithCommas(data.activeMemberCount)} Active Member Accounts)</span>
            </ListGroup.Item>
            <ListGroup.Item as='li' className='mb-1 p-1'>
              <Link to='/apps?status=All'>Apps</Link> <span className='d-md-block text-secondary'>({data && data.approvedApps} Active Apps)</span>
              { data && data.newApps === 1 &&
                <span className='d-md-block text-primary'>({data && data.newApps} New Registration)</span>
              }
              { data && data.newApps > 1 &&
                <span className='d-md-block text-primary'>({data && data.newApps} New Registrations)</span>
              }
            </ListGroup.Item>
            <ListGroup.Item as='li' className='mb-1 p-1'>
              <Link to='/developers'>Developer Content</Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={9} className='p-3 border'>
          <h3 className='h2'>FHIR Data<small className="text-secondary ml-4">{capitalizeFirstLetter(process.env.REACT_APP_ENVIRONMENT)} Environment</small></h3>
          <div>
						
            <h4 className="h4 mb-3 border-bottom-1">Patient Access API</h4>

            {process.env.REACT_APP_ENVIRONMENT === 'production' &&
              <p><strong>Capability Statement:</strong> https://cchp.healthlx.com:9090/fhir/metadata</p>
            }
            <Form noValidate onSubmit={memberDataForm.handleSubmit} inline className='border border-2 rounded mb-3 p-2'>
              <Form.Group>
                <Form.Label className='mr-2'>View FHIR Data: </Form.Label>
                <Form.Control
                  name='memberId'
                  type='text'
                  width='12'
                  value={memberDataForm.values.memberId}
                  onChange={memberDataForm.handleChange}
                  onBlur={memberDataForm.handleBlur}
                  isInvalid={!!memberDataForm.errors.memberId && memberDataForm.touched.memberId}
                  placeholder='Member ID'
                  className='mr-3'
                  aria-describedby='entermemberid'
                />
                <Form.Control.Feedback type="invalid">
                  {memberDataForm.errors.memberId}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type='submit' className='custom'>
                View Data
              </Button>
            </Form>
						
          </div>
          <div>
					
            <h4 className="h4 mb-3 border-bottom-1">Provider Directory API</h4>
            {process.env.REACT_APP_ENVIRONMENT === 'production' &&
              <p><strong>Capability Statement:</strong> https://cchp.healthlx.com:9091/fhir/metadata</p>
            }
            <Form noValidate onSubmit={providerDataForm.handleSubmit} inline className='border border-2 rounded mb-3 p-2'>
              <Form.Group>
                <Form.Label className='mr-2'>View FHIR Data: </Form.Label>
              </Form.Group>
              <Button type='submit' className='custom'>
                View Data
              </Button>
            </Form>

          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default isAuthenticated(Admin)
